import {isNotEmpty, matches} from "@mantine/form";
import {IAddress} from "./types";

export class AddressUtils {
    static transformInitialValues(address?: IAddress): IAddress {
        if (!address) {
            return ({
                streetAddress: "",
                city: "",
                state: "",
                zip: "",
            })
        } else {
            return {
                ...address,
            }
        }
    }

    static getValidationSchema(): Partial<Record<keyof IAddress, any>> {
        return {
            streetAddress: isNotEmpty(),
            city: isNotEmpty(),
            state: isNotEmpty(),
            zip: matches(/^\d{5}(?:[-\s]\d{4})?$/, "Invalid Zip Code"),
        }
    }
}
