import { DetailSettings } from "components/common"
import {
  Divider,
  Grid,
  Group,
  Skeleton,
  Text,
  useMantineTheme,
} from "@mantine/core"
import React, { Fragment, isValidElement } from "react"
import { UseFormReturnType } from "@mantine/form"
import { PermissionsReturn } from "hooks"

export type DetailProps<TData, TFormValues = void> = {
  settings: DetailSettings<TData, TFormValues>[]
  data?: TData
  asForm?: boolean
  loading?: boolean
  form?: UseFormReturnType<TFormValues>
  overrideFields?: Record<
    keyof TData | string,
    Partial<DetailSettings<TData, TFormValues>>
  >
  disableFields?: (keyof TData | string)[]
  disableFormEditFields?: (keyof TData | string)[]
  renderButton?: () => React.ReactNode
  addFields?: DetailSettings<TData, TFormValues>[]
  permissions?: PermissionsReturn
}

export function Detail<TData, TFormValues = void>({
  form,
  settings: incomingSettings,
  data,
  asForm,
  loading,
  overrideFields,
  disableFields,
  disableFormEditFields,
  renderButton,
  addFields,
  permissions,
}: DetailProps<TData, TFormValues>) {
  const theme = useMantineTheme()

  function renderSettings() {
    let settings: DetailSettings<TData, TFormValues>[] = incomingSettings
    if (addFields) {
      settings = [...incomingSettings, ...addFields]
    }
    settings = settings.filter(incoming => {
      return !(disableFields && disableFields.indexOf(incoming.key) !== -1)
    })
    return settings.map((incoming, i) => {
      let setting = incoming
      if (overrideFields && overrideFields[setting.key] !== undefined) {
        setting = {
          ...incoming,
          ...overrideFields[setting.key],
        }
      }
      const { Render, label, required } = setting

      const renderedLabel = isValidElement(label) ? (
        label
      ) : (
        <Text size="sm" color={theme.colors.dark[3]}>
          {label}
        </Text>
      )

      function renderLabel() {
        return (
          <Grid.Col sm={2}>
            <Group spacing={3}>
              {renderedLabel}
              {asForm && required && (
                <Text color={theme.colors.red[4]} size="sm">
                  *
                </Text>
              )}
            </Group>
          </Grid.Col>
        )
      }

      function renderComponent() {
        if (loading) {
          return (
            <Grid.Col sm={10}>
              <Skeleton height={20} />
            </Grid.Col>
          )
        } else if (asForm && form && Render.Form) {
          return (
            <Grid.Col sm={10}>
              {Render.Form({
                form: form,
                disabled:
                  disableFormEditFields &&
                  disableFormEditFields.indexOf(setting.key) !== -1,
                permissions: permissions,
                data: data,
              })}
            </Grid.Col>
          )
        } else if (data) {
          return (
            <Grid.Col sm={10}>
              <Render.Static data={data} permissions={permissions} />
            </Grid.Col>
          )
        } else {
          return null
        }
      }

      return (
        <Fragment key={i}>
          {renderLabel()}
          {renderComponent()}
          {i !== settings.length - 1 && (
            <Grid.Col>
              <Divider />
            </Grid.Col>
          )}
        </Fragment>
      )
    })
  }

  if (asForm) {
    return (
      <form>
        <Grid gutter={12}>
          {renderSettings()}
          {renderButton && (
            <Grid.Col mt={12}>
              <Group position="right">{renderButton()}</Group>
            </Grid.Col>
          )}
        </Grid>
      </form>
    )
  }

  return <Grid gutter={12}>{renderSettings()}</Grid>
}
