import { Button, Stack, Text } from "@mantine/core"
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { useEffect, useState } from "react"
import { useUserRoutes } from "routes"
import { ArrowRight } from "tabler-icons-react"
import { LoaderDefault } from "components/common"

interface Props {
  taskId: string | null
  homeId: string | null
}

export function HomeListingPaymentCheckout({ taskId, homeId }: Props) {
  const { routesResolver } = useUserRoutes()

  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    )

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (paymentIntent) {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!")
            break
          case "processing":
            setMessage("Your payment is processing.")
            break
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.")
            break
          default:
            setMessage("Something went wrong.")
            break
        }
      }
    })
  }, [stripe])

  const handleSubmit = async (e: any) => {
    e.preventDefault()

    if (!stripe || !elements || !homeId) {
      return
    }

    setIsLoading(true)

    const callback = `${
      window.location.origin
    }${routesResolver.getHomeListingPaymentConfirmationByHomeId(
      homeId
    )}?taskId=${taskId}`

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: callback,
      },
    })

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message || "An unexpected error occurred.")
    } else {
      setMessage("An unexpected error occurred.")
    }

    setIsLoading(false)
  }

  if (!stripe || !elements) {
    return <LoaderDefault text="Loading Checkout" />
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <Stack spacing={12}>
        <Text size="lg" weight={700}>
          Price: $500
        </Text>
        <PaymentElement
          id="payment-element"
          options={{ layout: "accordion" }}
        />
        {/*<button disabled={isLoading || !stripe || !elements} id="submit">*/}
        {/*    <span id="button-text">*/}
        {/*      {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}*/}
        {/*    </span>*/}
        {/*</button>*/}
        <Button
          ml="auto"
          disabled={isLoading || !stripe || !elements}
          loading={isLoading}
          type="submit"
          id="submit"
          rightIcon={<ArrowRight size={18} />}
        >
          <span id="button-text">Submit Payment</span>
        </Button>
      </Stack>
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}
