import { NoData } from "components/common"
import { DefaultPageWrapper } from "layouts"
import { useSearchParams } from "react-router-dom"
import { Center, Group, Loader, Stack, Text } from "@mantine/core"
import { embeddedDocumentHooks } from "features/embedded-documents"
import { useEffect } from "react"
import { taskHooks } from "features/tasks"
import { ReactComponent as FestivitiesSVG } from "assets/undraw/undraw_festivities.svg"
import { EOfferStatus, offerHooks } from "features/offers"

export function EmbeddedDocumentSignedPage() {
  const [searchParams] = useSearchParams()
  const authCode = searchParams.get("authCode")

  const { data: embeddedDocument, isLoading: isLoadingEmbeddedDocument } =
    embeddedDocumentHooks.useGetByAuthCode(authCode)

  const { mutateAsync: updateTask, isLoading: updatingTask } =
    taskHooks.useUpdate()

  const { mutateAsync: updateOffer, isLoading: updatingOffer } =
    offerHooks.useUpdate()

  async function markTaskAsDone(taskId: number) {
    await updateTask({ id: taskId, values: { completedOn: new Date() } })
  }

  async function markOfferAsCounterSigned(offerId: number) {
    await updateOffer({
      id: offerId,
      values: { status: EOfferStatus.COUNTER_SIGNED },
    })
  }

  // Automatically mark related signing task as complete
  useEffect(() => {
    if (
      embeddedDocument &&
      embeddedDocument.data?.attributes.task?.data &&
      !embeddedDocument.data?.attributes.task?.data.attributes.completedOn
    ) {
      markTaskAsDone(embeddedDocument.data.attributes.task.data.id)
    } else if (
      embeddedDocument &&
      embeddedDocument.data?.attributes.offer?.data &&
      embeddedDocument.data?.attributes.offer?.data.attributes.status !==
        EOfferStatus.COUNTER_SIGNED
    ) {
      markOfferAsCounterSigned(embeddedDocument.data.attributes.offer.data.id)
    }
  }, [embeddedDocument, isLoadingEmbeddedDocument])

  const renderContent = () => {
    if (isLoadingEmbeddedDocument || updatingTask) {
      return (
        <Center h={"100%"} w={"100%"}>
          <Group noWrap>
            <Loader size="sm" />
            <Text>
              Submitting signed document, please do not leave the page...
            </Text>
          </Group>
        </Center>
      )
    }

    if (!embeddedDocument) {
      return (
        <Center h={"100%"} w={"100%"}>
          <NoData title="Document not found" />
        </Center>
      )
    }

    return (
      <Center h={"100%"} w={"100%"}>
        <Stack spacing={0} py={24} align="center">
          <FestivitiesSVG width={600} height={200} />
          <Stack
            spacing={0}
            mt={18}
            style={{
              maxWidth: 500,
              textAlign: "center",
            }}
          >
            <Text
              size={24}
              gradient={{
                from: "red",
                to: "orange",
                deg: 45,
              }}
              weight={600}
              variant="gradient"
            >
              Nice work! You've signed the document.
            </Text>
            <Text size={18} mt={6} color="dimmed">
              Your concierge will be notified and will reach out to you shortly.
              You should receive a copy of the signed document in your email.
              Feel free to upload it to your Documents in your home dashboard
              for record keeping. You may now leave the page.
            </Text>
          </Stack>
        </Stack>
      </Center>
    )
  }

  return <DefaultPageWrapper>{renderContent()}</DefaultPageWrapper>
}
