import {Group, GroupProps, Paper, PaperProps, Stack, StackProps, Title as MantineTitle, TitleProps} from "@mantine/core";
function Section({children, ...rest}:PaperProps){
    return (
        <Paper withBorder {...rest}>
            {children}
        </Paper>
    )
}

function Header({children, ...rest}:GroupProps){
    return (
        <Group py={12} px={16} position="apart" align="flex-start" {...rest}>
            {children}
        </Group>
    )
}

function Body({children, ...rest}:StackProps){
    return (
        <Stack spacing={9} {...rest}>
            {children}
        </Stack>
    )
}

function Title({children, ...rest}:TitleProps){
    return (
        <MantineTitle order={4} {...rest}>
            {children}
        </MantineTitle>
    )
}

export const Content = {
    Section, Header, Body, Title
}