import {
    Avatar,
    createStyles,
    Group,
    Menu,
    UnstyledButton,
    Text,
    Box,
    Center,
    useMantineColorScheme,
    SegmentedControl,
    Stack, useMantineTheme,
} from "@mantine/core"
import {ChevronDown, Logout, Moon, Settings, Sun} from "tabler-icons-react"
import {useState} from "react"
import {authHooks} from "features/auth"
import {useClerk, useUser} from "@clerk/clerk-react"

const useStyles = createStyles((theme) => ({
    userMenu: {
        [theme.fn.smallerThan("xs")]: {
            display: "none",
        },
    },

    user: {
        color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
        padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
        borderRadius: theme.radius.sm,
        transition: "background-color 100ms ease",

        "&:hover": {
            backgroundColor:
                theme.colorScheme === "dark"
                    ? theme.colors.dark[8]
                    : theme.colors.gray[1],
        },
    },

    userActive: {
        backgroundColor:
            theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[1],
    },
}))

export function UserButton() {
    const {classes, cx} = useStyles()
    const {signOut} = useClerk()
    const theme = useMantineTheme()
    const {data: user} = authHooks.useMe()
    const [userMenuOpened, setUserMenuOpened] = useState(false)
    const {colorScheme, toggleColorScheme} = useMantineColorScheme()
    const {user: clerkUser} = useUser()

    async function onLogout() {
        await signOut()
    }

    return (
        <Menu
            position="bottom-end"
            transition="pop-top-right"
            onClose={() => setUserMenuOpened(false)}
            onOpen={() => setUserMenuOpened(true)}
        >
            <Menu.Target>
                <UnstyledButton
                    className={cx(classes.user, {[classes.userActive]: userMenuOpened})}
                >
                    <Group noWrap spacing={7}>
                        <Avatar
                            src={clerkUser?.imageUrl}
                            alt={user?.fullName}
                            radius="xl"
                            size={20}
                            mr={3}
                        />
                        <Text weight={500} size="sm" sx={{
                            lineHeight: 1,
                            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                display: "none"
                            },
                        }} mr={3}>
                            {user?.fullName}
                        </Text>
                        <Group sx={{
                            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                                display: "none"
                            },
                        }}>
                            <ChevronDown size={12}/>
                        </Group>

                    </Group>
                </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item>
                    <Group>
                        <Avatar
                            src={clerkUser?.imageUrl}
                            alt={user?.fullName}
                            radius="xl"
                            size={50}
                            mr={3}
                        />
                        <Stack spacing={6}>
                            <Text weight={600} size="sm" sx={{lineHeight: 1}} mr={3}>
                                {user?.fullName}
                            </Text>
                        </Stack>
                    </Group>
                </Menu.Item>
                <Menu.Divider/>
                <Menu.Label>Settings</Menu.Label>
                <Menu.Item
                    icon={<Settings size={18}/>}
                    onClick={async () => {
                        window?.Clerk?.openUserProfile()
                    }}
                >
                    User Settings
                </Menu.Item>
                <Menu.Item component={Group}>
                    <SegmentedControl
                        value={colorScheme}
                        onChange={(value: "light" | "dark") => toggleColorScheme(value)}
                        data={[
                            {
                                value: "light",
                                label: (
                                    <Center>
                                        <Sun size={16}/>
                                        <Box ml={10}>Light</Box>
                                    </Center>
                                ),
                            },
                            {
                                value: "dark",
                                label: (
                                    <Center>
                                        <Moon size={16}/>
                                        <Box ml={10}>Dark</Box>
                                    </Center>
                                ),
                            },
                        ]}
                    />
                </Menu.Item>
                <Menu.Divider/>
                <Menu.Item color="red" icon={<Logout size={16}/>} onClick={onLogout}>
                    <Text>Logout</Text>
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
    )
}
