import {ActionIcon, Group, Stack, useMantineTheme} from "@mantine/core";
import {UseFormReturnType} from "@mantine/form";
import React from "react";
import {CircleMinus} from "tabler-icons-react";

interface Props<TFormValues> {
    form: UseFormReturnType<{ items: TFormValues[] }>
    renderFormItem: (item: TFormValues, index: number) => React.ReactNode,
    renderButton: () => React.ReactNode,
    headers?: React.ReactNode,
}

export function ListItemsForm<TFormValues>(
    {
        form,
        renderFormItem,
        renderButton,
        headers
    }: Props<TFormValues>) {
    const theme = useMantineTheme()
    const fields = form.values.items.map((item, index) => {
        return (
            <Group noWrap key={index}>
                {renderFormItem(item, index)}
                <ActionIcon sx={{ visibility: index === 0 ? "hidden" : "initial"}} size="sm" onClick={() => form.removeListItem('items', index)}>
                    <CircleMinus size={18} strokeWidth={1.5} color={theme.colors.red[6]}/>
                </ActionIcon>
            </Group>
        )
    })

    return (
        <Stack spacing={9}>
            {headers &&
                <Group noWrap>
                    {headers}
                    <ActionIcon sx={{ visibility: "hidden" }} size="sm">
                        <CircleMinus size={18} strokeWidth={1.5} color={theme.colors.red[6]}/>
                    </ActionIcon>
                </Group>}
            {fields}
            <Group>
                {renderButton()}
            </Group>
        </Stack>
    )
}