import {
    createStyles,
    Title,
    Text,
    TextInput,
    Button,
    Group,
    Anchor,
    Center,
    Stack,
} from '@mantine/core';
import {IconArrowLeft} from '@tabler/icons';
import {Link} from "react-router-dom";
import {RoutesResolver} from "routes";
import {useState} from "react";
import {authHooks} from "features/auth";
import {IStrapiForgotPasswordValues} from "services/strapi";
import {useForm} from "@mantine/form";
import {ArrowRight} from "tabler-icons-react";

const useStyles = createStyles((theme) => ({
    controls: {
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column-reverse',
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
}));

export function ForgotPassword() {
    const {classes} = useStyles();
    const routesResolver = new RoutesResolver();
    const [sending, setSending] = useState(false);
    const {mutateAsync} = authHooks.useForgotPassword();
    const form = useForm({
        initialValues: {
            email: "",
        },

        validate: {
            email: (value) => /^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}$/i.test(value) ? null : "Please enter a valid email",
        },
    });

    async function onForgotPasswordSubmit(values: any) {
        setSending(true);
        if (values.email) {
            await mutateAsync({email: values.email} as IStrapiForgotPasswordValues);
        }
        setSending(false);
    }

    return (
        <Stack spacing={6}>
            <Title order={3}>
                Forgot your password?
            </Title>
            <Text color="dimmed" size="sm">
                Enter your email to get a reset link
            </Text>

            <form onSubmit={form.onSubmit(onForgotPasswordSubmit)}>
                <TextInput mt={12} label="Your email" placeholder="me@mycompany.com" required
                           {...form.getInputProps('email')}/>
                <Group position="apart" mt="lg" className={classes.controls}>
                    <Anchor color="dimmed" size="sm" className={classes.control}>
                        <Center inline>
                            <IconArrowLeft size={12} stroke={1.5}/>
                            <Anchor component={Link} to={routesResolver.login} ml={5}>Back to login page</Anchor>
                        </Center>
                    </Anchor>
                    <Button loading={sending} type="submit" className={classes.control}
                            rightIcon={<ArrowRight size={18}/>}>
                        Reset password
                    </Button>
                </Group>
            </form>

        </Stack>
    );
}
