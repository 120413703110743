import {
  ModelRoutes,
  ModelApiService,
  IStrapiResponse,
  IStrapiEntity,
} from "services/strapi"
import { IEmbeddedDocument } from "features/embedded-documents"
import { strapi } from "lib/strapi-api"
import axios from "axios"
import qs from "qs"

class EmbeddedDocumentApiSingleton extends ModelApiService<IEmbeddedDocument> {
  async getEmbeddedDocumentByAuthCode(
    authCode: string | null
  ): Promise<IStrapiResponse<IStrapiEntity<IEmbeddedDocument>> | undefined> {
    if (!authCode) {
      return undefined
    }
    const query = qs.stringify({
      authCode,
    })
    try {
      const res = await strapi.get(`/${this.route}/get-by-auth-code?${query}`)
      return res.data as IStrapiResponse<IStrapiEntity<IEmbeddedDocument>>
    } catch (e) {
      if (axios.isAxiosError(e)) {
        this.showErrorNotification("GetEmbeddedDocumentByAuthCode" as any, e)
        console.error(
          `[GetEmbeddedDocumentByAuthCode] Error in route: ${this.route}`,
          e.message
        )
      }
      return undefined
    }
  }
}

export const embeddedDocumentApi = new EmbeddedDocumentApiSingleton(
  ModelRoutes.EmbeddedDocuments
)
