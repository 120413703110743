import {useState} from "react";
import {
    Anchor,
    Table,
    CopyButton,
    ActionIcon,
    Group,
    Text,
    Tooltip,
    useMantineTheme, Avatar
} from "@mantine/core";
import {TablesHeaderSortable} from "components/common";
import {IFlatUser, IUser} from "features/users";
import dayjs from "dayjs";
import {GLOBAL_DATE_FORMAT} from "settings";
import {Copy, InfoCircle} from "tabler-icons-react";
import {ThemeManager} from "utils";
import {useNavigate} from "react-router-dom";
import {getInitialsFromFullName, IUserDatum} from "features/user-data";

interface Props {
    currentUser?: IFlatUser|null,
    userData: IFlatUser[],
    onSort?: (field: keyof IUser | keyof IUserDatum, reversed: boolean) => void
}

export function UsersTable({ userData, onSort}: Props) {
    const navigate = useNavigate();
    const [sortBy, setSortBy] = useState<keyof IUser | keyof IUserDatum | null>(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const theme = useMantineTheme();

    const setSorting = async (field: keyof IUser | keyof IUserDatum) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
        if (onSort) onSort(field, reversed);
    };

    const rows = userData.map((curData) => {
        const {role, email, createdAt, invitationToken, id, userDatum} = curData;

        function renderLogo() {
            if (userDatum) {
                return <Avatar size={24} src={userDatum.avatar?.url}/>
            } else {
                const assignee = `N/A`;
                return (
                    <Avatar color={assignee ? "cyan" : "red"} radius="xl" size={'sm'}>
                        {assignee || getInitialsFromFullName(userDatum)}
                    </Avatar>
                )
            }
        }

        return (
            <tr key={curData.id}>
                <td>
                    <Group spacing={9} noWrap>
                        {renderLogo()}
                        <Tooltip label={userDatum?.fullName} withArrow>
                            <Anchor
                                lineClamp={1}
                                sx={{maxWidth: 200}}
                                color="blue"
                                onClick={() => navigate(`${id}`)}
                                size="sm"
                            >
                                {userDatum?.fullName}
                            </Anchor>
                        </Tooltip>
                    </Group>
                </td>
                <td>{email}</td>
                <td>{role.name}</td>
                <td>{dayjs(createdAt).format(GLOBAL_DATE_FORMAT)}</td>
                <td>
                    {invitationToken ?
                        <Group spacing={0}>
                            <Text mr={6}>No</Text>
                            <CopyButton
                                value={`${window.location.origin}/auth/accept-invitation?invitationToken=${invitationToken}`}>
                                {({copied, copy}) => (
                                    <Tooltip label="Copy Invitation Link" withArrow position="left">
                                        <ActionIcon color={copied ? 'teal' : 'white'} onClick={copy}>
                                            <Copy size={18}/>
                                        </ActionIcon>
                                    </Tooltip>
                                )}
                            </CopyButton>
                        </Group>
                        :
                        <Text>Yes</Text>}
                </td>
            </tr>
        )
    });

    return (
        <Table>
            <thead>
            <tr>
                <TablesHeaderSortable sorted={sortBy === 'fullName'}
                                      reversed={reverseSortDirection}
                                      onSort={() => setSorting('fullName')}>Name</TablesHeaderSortable>
                <th>Email</th>
                <th>
                    <Group>
                        Role
                        <Tooltip
                            label={
                                <ul>
                                    <li>
                                        <Text span color="cyan" mr={3}>
                                            Client Employee:
                                        </Text>
                                        <Text color={ThemeManager.chooseColor(theme, true)} weight={500} span>
                                            access non-sensitive organization data (such as
                                            Projects, Documents, and Meetings) but are unable to manage other users.
                                        </Text>
                                    </li>
                                    <li>
                                        <Text span color="cyan" mr={3}>
                                            Client Administrator:
                                        </Text>
                                        <Text color={ThemeManager.chooseColor(theme, true)} weight={500} span>
                                            manage other users within their organization and access
                                            sensitive data, such as Billing.
                                        </Text>
                                    </li>
                                </ul>
                            }
                            color={ThemeManager.chooseColor(theme)}
                            withArrow
                            multiline
                            width={500}
                        >
                            <ActionIcon size="xs">
                                <InfoCircle size={14}/>
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                </th>
                <th>Created</th>
                <th>Accepted</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    )
}
