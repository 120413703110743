import {ModelHooks} from "services/strapi";
import { documentFolderApi } from "../services/document-folder.strapi.api";
import { IDocumentFolder } from "../types/document-folder.types";

class DocumentFolderHooks extends ModelHooks<IDocumentFolder> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const documentFolderHooks = new DocumentFolderHooks(documentFolderApi);
