import {Box, Button, Select, Stack, Title, Text, Radio, Image, Divider} from "@mantine/core"
import {isNotEmpty, useForm} from "@mantine/form"
import {IconArrowLeft, IconArrowRight} from "@tabler/icons"
import {Content, LoaderDefault, Page} from "components/common"
import {IHomeFormReviewListingFormValues, homeHooks} from "features/homes"
import {useNavigate, useParams, useSearchParams} from "react-router-dom"
import {useUserRoutes} from "routes"
import {ErrorUtils} from "utils/error"
import {Carousel} from '@mantine/carousel';

export function HomeFormReviewListingPage() {
    const {homeId} = useParams()
    const [searchParams] = useSearchParams()
    const taskId = searchParams.get("taskId")

    const navigate = useNavigate()
    const {routesResolver} = useUserRoutes()
    const {data: listing, isLoading: isLoadingListing} = homeHooks.useGetListing(parseInt(homeId!))
    const {mutateAsync} = homeHooks.useSubmitReviewListing()

    const form = useForm<IHomeFormReviewListingFormValues>({
        validate: {
            photosApproved: isNotEmpty("This field is required"),
            descriptionApproved: isNotEmpty("This field is required"),
        },
        validateInputOnChange: true,
        initialValues: {
            homeId: parseInt(homeId || ""),
            taskId: parseInt(taskId || ""),
            photosApproved: "",
            descriptionApproved: "",
        },
    })

    async function onSubmit() {
        if (!homeId) return
        if (!form.validate().hasErrors) {
            const payload: IHomeFormReviewListingFormValues = {
                homeId: parseInt(homeId),
                homeListingId: listing?.id || -1,
                taskId: form.values.taskId,
                photosApproved: form.values.photosApproved === "true",
                descriptionApproved: form.values.descriptionApproved === "true",
            }

            try {
                await mutateAsync(payload)
                navigate(routesResolver.getHomeDetailsById(parseInt(homeId || "")))
            } catch (error) {
                ErrorUtils.handleError(
                    "Error submitting Review Listing form",
                    "We apologize, something went wrong submitting your form",
                    error
                )
            }
        }
    }

    if (isLoadingListing) {
        return <LoaderDefault text="Loading your home"/>
    }

    return (
        <Content.Body>
            <Stack spacing={6}>
                <Page.Title title="Review Home Listing" link={{
                    to: routesResolver.getHomeDetailsById(parseInt(homeId || "")),
                    label: "Back to Home"
                }}/>
                <Divider mb={12}/>
                <form>
                    <Stack spacing={"lg"}>
                        <Stack spacing={6}>
                            <Text weight={600}>Your Listing Photos</Text>
                            <Carousel sx={{maxWidth: 500}} withIndicators w="100%" height={300}>
                                {listing?.photos.map((photo, index) => (
                                    <Carousel.Slide key={index} h={300} w="100%">
                                        <Image height={300} width="100%" src={photo.url}/>
                                    </Carousel.Slide>
                                ))}
                            </Carousel>
                            <Radio.Group size="sm" {...form.getInputProps('photosApproved')}>
                                <Radio value="true" label="I approve the listing phtos"/>
                                <Radio value="false" label="I do not approve the listing phtos"/>
                            </Radio.Group>
                        </Stack>
                        <Stack spacing={6} mt={24}>
                            <Text weight={600}>Your Listing Description</Text>
                            <Text>
                                {listing?.description}
                            </Text>
                            <Radio.Group size="sm" {...form.getInputProps('descriptionApproved')}>
                                <Radio value="true" label="I approve the description"/>
                                <Radio value="false" label="I do not approve the description"/>
                            </Radio.Group>
                        </Stack>
                    </Stack>
                    <Button rightIcon={<IconArrowRight/>} mt={"lg"} onClick={onSubmit} disabled={!form.isValid()}>
                        Submit
                    </Button>
                </form>
            </Stack>
        </Content.Body>
    )
}
