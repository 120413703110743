import {Button, Grid, TextInput} from '@mantine/core';
import {useForm} from '@mantine/form';
import {Filter} from "tabler-icons-react";

interface Props {
    onSubmitQuery?: (query: any) => void,
    initialValues?: any,
}

interface FormValues {
    name: string,
    email: number
}

export function UserFilters({onSubmitQuery, initialValues}: Props) {
    const form = useForm<FormValues>({
        initialValues: initialValues || {
            name: "",
            email: ""
        }
    })

    function onSubmit(values: typeof form.values) {
        if (onSubmitQuery) {
            onSubmitQuery(values)
        }
    }

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Grid>
                <Grid.Col span={5}>
                    <TextInput
                        placeholder="Name"
                        {...form.getInputProps('name')}
                    />
                </Grid.Col>
                <Grid.Col span={5}>
                    <TextInput
                        placeholder="Email"
                        {...form.getInputProps('email')}
                    />
                </Grid.Col>
                <Grid.Col span={2}>
                    <Button color="blue" leftIcon={<Filter size={18}/>} fullWidth type="submit">
                        Filter Results
                    </Button>
                </Grid.Col>
            </Grid>
        </form>
    )
}
