import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom"
import { LoaderDefault } from "components/common"
import { AdminWrapper, AppHeader, AppWrapper } from "layouts"
import {
  AdminDashboard,
  Dashboard,
  HomeContent,
  HomeCreatePage,
  HomePage,
  Users,
  HomeSimpleSaleSurvey,
  HomeRecentlySoldNearYou,
  OffersPage,
  EmbeddedDocumentSignedPage,
  HomeFormReviewListingPage,
  HomeFormReviewPricingPage,
  HomeFormScheduleSignLockboxDeliveryPage,
  HomeListingPaymentPage,
  HomeFormVerifyIdentityPage,
  HomeListingPaymentConfirmation,
  HomeSignTaskDocumentPage,
  HomeSignCounterOfferDocumentPage,
} from "pages"
import { RouteParams, RouteSegments } from "./segments"
import { authHooks } from "features/auth"
import { usePermissions } from "hooks"
import { RedirectToSignIn, SignedIn, SignedOut } from "@clerk/clerk-react"
import { ETaskFormTypes } from "features/tasks"

export function AppRoutes() {
  const { isLoading, data: me } = authHooks.useMe()
  const permissions = usePermissions()

  const redirectPath = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const redirectPath = urlParams.get("redirect")
    return redirectPath ? `/?redirect=${redirectPath}` : ""
  }

  function renderAllRoutes() {
    if (me && !isLoading) {
      return (
        <Route
          element={<AppHeader />}
          errorElement={<div>There's been an error!</div>}
        >
          {permissions.isAppAdmin && (
            <Route path={RouteSegments.Admin} element={<AdminWrapper />}>
              <Route
                path={RouteSegments.EmbeddedDocumentSigned}
                element={<EmbeddedDocumentSignedPage />}
              />
              <Route path={RouteSegments.Dashboard} element={<Outlet />}>
                <Route index element={<AdminDashboard />} />
              </Route>
              <Route path={RouteSegments.Users} element={<Outlet />}>
                <Route index element={<Users />} />
                <Route path={RouteParams.ClerkUserId} element={<Outlet />}>
                  <Route path={RouteSegments.Homes} element={<Dashboard />}>
                    <Route path={RouteParams.HomeId} element={<HomePage />}>
                      <Route path={RouteSegments.Forms} element={<Outlet />}>
                        {/* <Route
                          path={ETaskFormTypes.CASH_OFFER}
                          element={<HomeRequestCashOffer />}
                        /> */}
                        <Route
                          path={ETaskFormTypes.SIMPLE_SALE}
                          element={<HomeSimpleSaleSurvey />}
                        />
                        <Route
                          path={ETaskFormTypes.VERIFY_IDENTITY}
                          element={<HomeFormVerifyIdentityPage />}
                        />
                        <Route
                          path={ETaskFormTypes.HOME_LISTING_PAYMENT}
                          element={<HomeListingPaymentPage />}
                        />
                        <Route
                          path={ETaskFormTypes.REVIEW_LISTING}
                          element={<HomeFormReviewListingPage />}
                        />
                        <Route
                          path={ETaskFormTypes.REVIEW_PRICING}
                          element={<HomeFormReviewPricingPage />}
                        />
                        <Route
                          path={
                            ETaskFormTypes.SCHEDULE_SIGN_AND_LOCKBOX_DELIVERY
                          }
                          element={<HomeFormScheduleSignLockboxDeliveryPage />}
                        />
                        <Route
                          path={ETaskFormTypes.REVIEW_OFFERS}
                          element={<OffersPage />}
                        />
                      </Route>
                      <Route
                        path={RouteSegments.HomeListingPaymentConfirmation}
                        element={<HomeListingPaymentConfirmation />}
                      />
                      <Route
                        path={RouteSegments.RecentlySoldNearYou}
                        element={<HomeRecentlySoldNearYou />}
                      />
                      <Route
                        path={RouteParams.HomeTab}
                        element={<HomeContent />}
                      />
                      <Route path={RouteSegments.Tasks} element={<Outlet />}>
                        <Route path={RouteParams.TaskId} element={<Outlet />}>
                          <Route
                            path={RouteSegments.EmbeddedDocument}
                            element={<HomeSignTaskDocumentPage />}
                          />
                        </Route>
                      </Route>
                      <Route path={RouteSegments.Offers} element={<Outlet />}>
                        <Route path={RouteParams.OfferId} element={<Outlet />}>
                          <Route
                            path={RouteSegments.EmbeddedDocument}
                            element={<HomeSignCounterOfferDocumentPage />}
                          />
                        </Route>
                      </Route>
                      <Route
                        index
                        element={
                          <Navigate to={RouteSegments.Details} replace />
                        }
                      />
                      <Route
                        path={RouteSegments.Wildcard}
                        element={
                          <Navigate replace to={RouteSegments.Details} />
                        }
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route
                index
                element={<Navigate replace to={RouteSegments.Organizations} />}
              />
              <Route
                path={RouteSegments.Wildcard}
                element={<Navigate replace to={RouteSegments.Organizations} />}
              />
            </Route>
          )}
          <Route element={<AppWrapper />}>
            <Route
              path={RouteSegments.EmbeddedDocumentSigned}
              element={<EmbeddedDocumentSignedPage />}
            />
            <Route path={RouteSegments.Homes} element={<Dashboard />}>
              <Route path={RouteSegments.Create} element={<HomeCreatePage />} />

              <Route path={RouteParams.HomeId} element={<HomePage />}>
                <Route path={RouteSegments.Forms} element={<Outlet />}>
                  {/* <Route
                    path={ETaskFormTypes.CASH_OFFER}
                    element={<HomeRequestCashOffer />}
                  /> */}
                  <Route
                    path={ETaskFormTypes.SIMPLE_SALE}
                    element={<HomeSimpleSaleSurvey />}
                  />
                  <Route
                    path={ETaskFormTypes.VERIFY_IDENTITY}
                    element={<HomeFormVerifyIdentityPage />}
                  />
                  <Route
                    path={ETaskFormTypes.HOME_LISTING_PAYMENT}
                    element={<HomeListingPaymentPage />}
                  />
                  <Route
                    path={ETaskFormTypes.REVIEW_LISTING}
                    element={<HomeFormReviewListingPage />}
                  />
                  <Route
                    path={ETaskFormTypes.REVIEW_PRICING}
                    element={<HomeFormReviewPricingPage />}
                  />
                  <Route
                    path={ETaskFormTypes.SCHEDULE_SIGN_AND_LOCKBOX_DELIVERY}
                    element={<HomeFormScheduleSignLockboxDeliveryPage />}
                  />
                  <Route
                    path={ETaskFormTypes.REVIEW_OFFERS}
                    element={<OffersPage />}
                  />
                </Route>
                <Route
                  path={RouteSegments.HomeListingPaymentConfirmation}
                  element={<HomeListingPaymentConfirmation />}
                />

                <Route
                  path={RouteSegments.RecentlySoldNearYou}
                  element={<HomeRecentlySoldNearYou />}
                />
                <Route path={RouteParams.HomeTab} element={<HomeContent />} />
                <Route path={RouteSegments.Tasks} element={<Outlet />}>
                  <Route path={RouteParams.TaskId} element={<Outlet />}>
                    <Route
                      path={RouteSegments.EmbeddedDocument}
                      element={<HomeSignTaskDocumentPage />}
                    />
                  </Route>
                </Route>
                <Route path={RouteSegments.Offers} element={<Outlet />}>
                  <Route path={RouteParams.OfferId} element={<Outlet />}>
                    <Route
                      path={RouteSegments.EmbeddedDocument}
                      element={<HomeSignCounterOfferDocumentPage />}
                    />
                  </Route>
                </Route>
                <Route
                  index
                  element={<Navigate to={RouteSegments.Today} replace />}
                />
                <Route
                  path={RouteSegments.Wildcard}
                  element={<Navigate replace to={RouteSegments.Details} />}
                />
              </Route>
            </Route>
            {/* Generated React Router Pages */}

            <Route
              index
              element={<Navigate to={RouteSegments.Homes} replace />}
            />
            <Route
              path={RouteSegments.Wildcard}
              element={<Navigate replace to={RouteSegments.Homes} />}
            />
          </Route>
          <Route
            index
            element={
              <Navigate
                replace
                to={
                  permissions.isAppAdmin
                    ? RouteSegments.Admin
                    : `${RouteSegments.Users}/${me.clerkUserId}`
                }
              />
            }
          />
          <Route
            path={RouteSegments.Wildcard}
            element={
              <Navigate
                replace
                to={
                  permissions.isAppAdmin
                    ? RouteSegments.Admin
                    : `${RouteSegments.Users}/${me.clerkUserId}`
                }
              />
            }
          />
        </Route>
      )
    } else {
      return <Route element={<LoaderDefault text="Loading Application" />} />
    }
  }

  return (
    <>
      <SignedIn>
        <BrowserRouter>
          <Routes>{renderAllRoutes()}</Routes>
        </BrowserRouter>
      </SignedIn>
      <SignedOut>
        <BrowserRouter>
          <Routes>
            <Route
              index
              element={<RedirectToSignIn redirectUrl={redirectPath()} />}
            />
            <Route
              path={RouteSegments.Wildcard}
              element={<RedirectToSignIn redirectUrl={redirectPath()} />}
            />
          </Routes>
        </BrowserRouter>
      </SignedOut>
    </>
  )
}

export default AppRoutes
