import { ModelHooks, ModelRoutes } from "services/strapi"
import { homeApi } from "../services/home.strapi.api"
import {
  IHome,
  IHomeCashOfferFormValues,
  IHomeFormReviewListingFormValues,
  IHomeFormReviewPricingFormValues,
  IHomeFormScheduleSignLockboxDeliveryFormValues,
  IHomeSimpleSaleSurveyValues,
  IHomeFormVerifyIdentityValues,
  ICreatePaymenetIntentValues,
} from "../types/home.types"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { ApiUtils } from "utils/api"
import { ETaskFormTypes } from "features/tasks"

class HomeHooks extends ModelHooks<IHome> {
  useGetSellScore = (homeId: number) => {
    return useQuery([this.modelQueryKey, homeId, "sell-score"], () =>
      homeApi.getSellScore(homeId)
    )
  }

  useGetEstimatedMarketValue = (homeId: number) => {
    return useQuery(
      [this.modelQueryKey, homeId, "estimated-market-value"],
      () => homeApi.getEstimatedMarketValue(homeId)
    )
  }

  useGetRecentlySold = (homeId: number) => {
    return useQuery([this.modelQueryKey, homeId, "recently-sold"], () =>
      homeApi.getRecentlySoldNear(homeId)
    )
  }

  useSubmitCashOfferForm = (extraInvalidateKeys: ModelRoutes[] = []) => {
    const queryClient = useQueryClient()
    return useMutation(
      (values: IHomeCashOfferFormValues) =>
        homeApi.submitInteractiveForm(values.id, ETaskFormTypes.CASH_OFFER, {
          ...values,
        }),
      {
        onSuccess: async () => {
          ApiUtils.handleSuccess(
            queryClient,
            this.modelQueryKey,
            extraInvalidateKeys
          )
        },
      }
    )
  }

  useRequestAvm = (extraInvalidateKeys: ModelRoutes[] = []) => {
    const queryClient = useQueryClient()
    return useMutation((homeId?: number) => homeApi.requestAvm(homeId), {
      onSuccess: async () => {
        ApiUtils.handleSuccess(
          queryClient,
          this.modelQueryKey,
          extraInvalidateKeys
        )
      },
    })
  }

  useSendMessage = (extraInvalidateKeys: ModelRoutes[] = []) => {
    const queryClient = useQueryClient()
    return useMutation(
      (opts: { homeId: number; message: string }) =>
        homeApi.sendMessage(opts.homeId, opts.message),
      {
        onSuccess: async () => {
          ApiUtils.handleSuccess(
            queryClient,
            this.modelQueryKey,
            extraInvalidateKeys
          )
        },
      }
    )
  }

  useSubmitSimpleSaleSurvey = (extraInvalidateKeys: ModelRoutes[] = []) => {
    const queryClient = useQueryClient()
    return useMutation(
      (values: IHomeSimpleSaleSurveyValues) =>
        homeApi.submitInteractiveForm(values.id, ETaskFormTypes.SIMPLE_SALE, {
          ...values,
        }),
      {
        onSuccess: async () => {
          ApiUtils.handleSuccess(
            queryClient,
            this.modelQueryKey,
            extraInvalidateKeys
          )
        },
      }
    )
  }

  useSubmitReviewPricing = (extraInvalidateKeys: ModelRoutes[] = []) => {
    const queryClient = useQueryClient()
    return useMutation(
      (values: IHomeFormReviewPricingFormValues) =>
        homeApi.submitInteractiveForm(
          values.homeId,
          ETaskFormTypes.REVIEW_PRICING,
          { ...values },
          values.taskId
        ),
      {
        onSuccess: async () => {
          ApiUtils.handleSuccess(
            queryClient,
            this.modelQueryKey,
            extraInvalidateKeys
          )
        },
      }
    )
  }

  useSubmitReviewListing = (extraInvalidateKeys: ModelRoutes[] = []) => {
    const queryClient = useQueryClient()
    return useMutation(
      (values: IHomeFormReviewListingFormValues) =>
        homeApi.submitInteractiveForm(
          values.homeId,
          ETaskFormTypes.REVIEW_LISTING,
          { ...values },
          values.taskId
        ),
      {
        onSuccess: async () => {
          ApiUtils.handleSuccess(
            queryClient,
            this.modelQueryKey,
            extraInvalidateKeys
          )
        },
      }
    )
  }

  useSubmitScheduleSignLockboxDelivery = (
    extraInvalidateKeys: ModelRoutes[] = []
  ) => {
    const queryClient = useQueryClient()
    return useMutation(
      (values: IHomeFormScheduleSignLockboxDeliveryFormValues) =>
        homeApi.submitInteractiveForm(
          values.id,
          ETaskFormTypes.SCHEDULE_SIGN_AND_LOCKBOX_DELIVERY,
          { ...values },
          values.taskId
        ),
      {
        onSuccess: async () => {
          ApiUtils.handleSuccess(
            queryClient,
            this.modelQueryKey,
            extraInvalidateKeys
          )
        },
      }
    )
  }

  useSubmitIdentityVerification = (extraInvalidateKeys: ModelRoutes[] = []) => {
    const queryClient = useQueryClient()
    return useMutation(
      (values: IHomeFormVerifyIdentityValues) =>
        homeApi.submitInteractiveForm(
          values.id,
          ETaskFormTypes.VERIFY_IDENTITY,
          { ...values },
          values.taskId
        ),
      {
        onSuccess: async () => {
          ApiUtils.handleSuccess(
            queryClient,
            this.modelQueryKey,
            extraInvalidateKeys
          )
        },
      }
    )
  }

  useCreateHomeListingPaymentIntent = () => {
    return useMutation(
      (values: ICreatePaymenetIntentValues) =>
        homeApi.createHomeListingPaymentIntent(values.id, { ...values }),
      {}
    )
  }

  useGetPricingModel = (homeId: number) => {
    return useQuery([this.modelQueryKey, homeId, "home-pricing-model"], () =>
      homeApi.getPricingModel(homeId)
    )
  }

  useGetListing = (homeId: number) => {
    return useQuery([this.modelQueryKey, homeId, "listing"], () =>
      homeApi.getListing(homeId)
    )
  }
}

export const homeHooks = new HomeHooks(homeApi)
