import { IStrapiEntity } from "services/strapi"
import { IDocumentFolder } from "features/document-folders"
import { Group, Loader, Table, Text } from "@mantine/core"
import { IconFolder } from "@tabler/icons"
import { NoData } from "components/common"
import { useState } from "react"

interface Props {
  data?: IStrapiEntity<IDocumentFolder>[]
  loading?: boolean
  onFolderClick?: (folder: IStrapiEntity<IDocumentFolder>) => void
}

export function DocumentFoldersFolderView({
  data,
  loading,
  onFolderClick,
}: Props) {
  const [hoveredFolder, setHoveredFolder] = useState<number | null>(null)

  if (loading) {
    return (
      <Table>
        <tbody>
          <tr>
            <td>
              <Group noWrap>
                <Loader size="sm" />
                <Text>Loading...</Text>
              </Group>
            </td>
          </tr>
        </tbody>
      </Table>
    )
  }

  if (data?.length === 0) {
    return <NoData title="No Data" />
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Folder</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((documentFolder) => (
          <tr
            key={documentFolder?.id}
            style={{ cursor: "pointer" }}
            onMouseEnter={() => setHoveredFolder(documentFolder.id)}
            onMouseLeave={() => setHoveredFolder(null)}
            onClick={() => onFolderClick && onFolderClick(documentFolder)}
          >
            <td>
              <Group>
                <IconFolder size={18} />
                <Text underline={hoveredFolder === documentFolder.id}>
                  {documentFolder?.attributes?.name}
                </Text>
              </Group>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}
