import { Box, Button, Select, Stack, Text, Title } from "@mantine/core"
import { isNotEmpty, useForm } from "@mantine/form"
import { IconArrowLeft, IconArrowRight } from "@tabler/icons"
import { Content } from "components/common"
import { IHomeSimpleSaleSurveyValues, homeHooks } from "features/homes"
import { useNavigate, useParams } from "react-router-dom"
import { useUserRoutes } from "routes"
import { ErrorUtils } from "utils/error"

export function HomeSimpleSaleSurvey() {
  const { homeId } = useParams()
  const navigate = useNavigate()
  const { routesResolver } = useUserRoutes()

  const { mutateAsync } = homeHooks.useSubmitSimpleSaleSurvey()

  const form = useForm<IHomeSimpleSaleSurveyValues>({
    validate: {
      buy: isNotEmpty("This field is required"),
      hasBuyerAgent: (value, values) =>
        values.buy === "yes" && value === "" ? "This field is required" : null,
      wantsBuyerAgent: (value, values) =>
        values.hasBuyerAgent === "no" && value === ""
          ? "This field is required"
          : null,
      primaryResidence: isNotEmpty("This field is required"),
      homeOwnershipTime: isNotEmpty("This field is required"),
      reason: isNotEmpty("This field is required"),
      timeline: isNotEmpty("This field is required"),
    },
    validateInputOnChange: true,
    initialValues: {
      id: parseInt(homeId || ""),
      buy: "",
      hasBuyerAgent: "",
      wantsBuyerAgent: "",
      primaryResidence: "",
      homeOwnershipTime: "",
      reason: "",
      timeline: "",
    },
  })

  async function onSubmit() {
    if (!homeId) return
    if (!form.validate().hasErrors) {
      const payload: IHomeSimpleSaleSurveyValues = {
        id: parseInt(homeId),
        buy: form.values.buy,
        hasBuyerAgent: form.values.hasBuyerAgent,
        wantsBuyerAgent: form.values.wantsBuyerAgent,
        primaryResidence: form.values.primaryResidence,
        homeOwnershipTime: form.values.homeOwnershipTime,
        reason: form.values.reason,
        timeline: form.values.timeline,
      }

      try {
        await mutateAsync(payload)
        navigate(routesResolver.getHomeDetailsById(parseInt(homeId || "")))
      } catch (error) {
        ErrorUtils.handleError(
          "Error submitting Simple Sale survey",
          "We apologize, something went wrong submitting your survey",
          error
        )
      }
    }
  }

  return (
    <Content.Body>
      <Stack>
        <Box>
          <Button
            leftIcon={<IconArrowLeft />}
            onClick={() =>
              navigate(
                routesResolver.getHomeServicesById(parseInt(homeId || ""))
              )
            }
          >
            Back
          </Button>
        </Box>
        <Title>Simple sale</Title>
        <Text>
          You've chosen to save money! Once you submit this survey, your home
          selling journey will begin.
        </Text>
        <form>
          <Stack spacing={"lg"}>
            <Select
              label="Do you also need to buy a home?"
              required
              data={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              {...form.getInputProps("buy")}
              onChange={(value: any) => {
                form.setFieldValue("hasBuyerAgent", "")
                form.setFieldValue("wantsBuyerAgent", "")
                form.setFieldValue("buy", value)
              }}
            />
            {form.values.buy === "yes" && (
              <Select
                label="Have you already signed an agreement to work with a buyer's agent or Realtor?"
                required={form.values.buy === "yes"}
                data={[
                  { value: "yes", label: "Yes" },
                  { value: "no", label: "No" },
                ]}
                {...form.getInputProps("hasBuyerAgent")}
              />
            )}
            {form.values.hasBuyerAgent === "no" && (
              <Stack spacing={1}>
                <Select
                  label="Would you like us to send your contact information to a buyer's agent from our network of elite agents?"
                  required={form.values.hasBuyerAgent === "no"}
                  data={[
                    {
                      value: "yes",
                      label: "Yes, I would like someone to reach out",
                    },
                    {
                      value: "no",
                      label: "No, I'll find my own buyer's agent",
                    },
                  ]}
                  {...form.getInputProps("wantsBuyerAgent")}
                />

                {form.values.wantsBuyerAgent === "yes" && (
                  <Text size={"sm"} italic fw={600}>
                    After submitting your survey, a buyer's agent will reach out
                    within 48 hours.
                  </Text>
                )}
              </Stack>
            )}
            <Select
              label="Is this your primary residence?"
              required
              data={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              {...form.getInputProps("primaryResidence")}
            />
            <Select
              label="How long have you owned your home?"
              required
              data={[
                { value: "less than a year", label: "Less than a year" },
                { value: "2-5 years", label: "2-5 years" },
                { value: "6-10 years", label: "6-10 years" },
                { value: "10+ years", label: "10+ years" },
              ]}
              {...form.getInputProps("homeOwnershipTime")}
            />
            <Select
              label="What is the primary reason you are considering selling your home?"
              required
              data={[
                { value: "relocation", label: "Relocation" },
                { value: "life changes", label: "Life changes" },
                { value: "financially driven", label: "Financially driven" },
                {
                  value: "upsizing or downsizing",
                  label: "Upsizing or downsizing",
                },
                {
                  value: "other/prefer not to say",
                  label: "Other/Prefer not to say",
                },
              ]}
              {...form.getInputProps("reason")}
            />
            <Select
              label="When do you hope to sell your home?"
              required
              data={[
                { value: "asap", label: "ASAP" },
                { value: "within 3 months", label: "Within 3 months" },
                { value: "4-6 months", label: "4-6 Months" },
                {
                  value: "more than 6 months",
                  label: "More than 6 months",
                },
                {
                  value: "undecided",
                  label: "Undecided",
                },
              ]}
              {...form.getInputProps("timeline")}
            />
          </Stack>

          <Button rightIcon={<IconArrowRight />} mt={"lg"} onClick={onSubmit}>
            Submit
          </Button>
        </form>
      </Stack>
    </Content.Body>
  )
}
