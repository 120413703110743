import React, {useEffect, useState} from "react"
import ReactDOM from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
import {
    MantineProvider,
    ColorSchemeProvider,
    ColorScheme,
} from "@mantine/core"
import {NotificationsProvider} from "@mantine/notifications"
import {AppRoutes} from "routes"
import {QueryClientProvider} from "@tanstack/react-query"
import {ModalsProvider} from "@mantine/modals"
import {queryClient} from "./settings"
import {useColorScheme} from "@mantine/hooks"
import {clerkJS, clerkPubKey} from "lib/clerk-js"
import {ClerkProvider} from "@clerk/clerk-react"
import {strapi} from "lib/strapi-api"
import {LoaderDefault} from "components/common"
import {LoadScript} from "@react-google-maps/api";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

function Entrypoint() {
    const [appIsInitialized, setAppIsInitialized] = useState(false)
    const preferredColorScheme = useColorScheme()
    const localStorageScheme = localStorage.getItem("colorScheme")
    const [colorScheme, setColorScheme] = useState<ColorScheme>(
        (localStorageScheme as ColorScheme) || preferredColorScheme
    )
    const toggleColorScheme = (value?: ColorScheme) => {
        localStorage.setItem(
            "colorScheme",
            value || (colorScheme === "dark" ? "light" : "dark")
        )
        setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"))
    }

    useEffect(() => {
        init()
    }, [])

    async function init() {
        setAppIsInitialized(false)
        await clerkJS.load()
        strapi.interceptors.request.use(async (config) => {
            const token = await clerkJS.session?.getToken()
            config = {
                ...config,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            return config
        })
        setAppIsInitialized(true)
    }

    if (!appIsInitialized) {
        return <LoaderDefault text="Loading application"/>
    }

    return (
        <ClerkProvider publishableKey={clerkPubKey}>
            <ColorSchemeProvider
                colorScheme={colorScheme}
                toggleColorScheme={toggleColorScheme}
            >
                <MantineProvider
                    withGlobalStyles
                    theme={{
                        colorScheme: colorScheme,
                        fontFamily: "Open Sans, sans-serif",
                        headings: {
                            fontFamily: "Inter, sans-serif",
                        },
                        components: {
                            Anchor: {
                                styles: (theme) => ({
                                    root: {
                                        color: theme.colors.blue[6],
                                    },
                                }),
                            },
                        },
                        globalStyles: (theme) => ({
                            body: {
                                margin: 0,
                                padding: 0,
                                fontWeight: 500,
                                WebkitFontSmoothing: "antialiased",
                                MozOsxFontSmoothing: "grayscale",
                                height: "100vh",
                            },
                            ".mantine-Modal-modal": {
                                padding: 0
                            },
                            th: {
                                fontSize: "13px !important",
                            },
                            ".mantine-Modal-header": {
                                padding: "12px 18px",
                                boxSizing: "border-box",
                                marginRight: 0,
                                marginBottom: 0,
                                borderBottom: `1px solid ${theme.colors.gray[3]}`,
                                ".mantine-Text-root": {
                                    fontSize: 16,
                                }
                            },
                            ".mantine-Modal-body": {
                                borderBottomLeftRadius: 4,
                                borderBottomRightRadius: 4,
                                padding: 16,
                                backgroundColor: theme.colors.gray[0]
                            },
                        }),
                    }}
                >
                    <NotificationsProvider>
                        <QueryClientProvider client={queryClient}>
                            <ModalsProvider>
                                <LoadScript googleMapsApiKey="AIzaSyBtiVvAa2uyVzujUsVPO7zhN6tbV6yV6_w" libraries={['places']}>
                                    <AppRoutes/>
                                </LoadScript>
                            </ModalsProvider>
                        </QueryClientProvider>
                    </NotificationsProvider>
                </MantineProvider>
            </ColorSchemeProvider>
        </ClerkProvider>
    )
}

root.render(<Entrypoint/>)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
