import {ModelHooks} from "services/strapi";
import { conciergeApi } from "../services/concierge.strapi.api";
import { IConcierge } from "../types/concierge.types";

class ConciergeHooks extends ModelHooks<IConcierge> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const conciergeHooks = new ConciergeHooks(conciergeApi);
