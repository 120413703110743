import { IStrapiEntity, StrapiUtils } from "services/strapi"
import { ListItemsTable, ListItemsTableProps } from "components/common"
import { IOffer, IOfferFormValues, TableSettings } from "features/offers"
import { useUserRoutes } from "routes"

interface Props
  extends Omit<
    ListItemsTableProps<IOffer & { id: number }, IOfferFormValues>,
    "settings" | "data"
  > {
  data?: IStrapiEntity<IOffer>[]
}

export function OffersListTable({ data, ...rest }: Props) {
  const { routesResolver } = useUserRoutes()
  return (
    <ListItemsTable
      {...rest}
      data={StrapiUtils.flattenStrapiEntities(data)}
      settings={TableSettings({
        routesResolver,
      })}
    />
  )
}
