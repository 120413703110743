import { ListItemsTableSettings } from "components/common"
import { IHomeRecentlySoldNear } from "features/homes"
import { Text } from "@mantine/core"
import { RoutesResolver } from "routes"
import dayjs from "dayjs"
import currency from "currency.js"

interface TableSettingProps {
  routesResolver: RoutesResolver
}

export type TRecentlySoldTableSettings = (
  props: TableSettingProps
) => ListItemsTableSettings<IHomeRecentlySoldNear, void>[]

export const RecentlySoldTableSettings: TRecentlySoldTableSettings = () => [
  {
    key: "streetAddress",
    label: "Street Address",
    isNotFormValue: true,
    required: false,
    Render: {
      Static: function ({ data }) {
        const { streetAddress } = data
        return <Text>{streetAddress}</Text>
      },
    },
  },
  {
    key: "soldPrice",
    label: "Sold Price",
    isNotFormValue: true,
    required: false,
    Render: {
      Static: function ({ data }) {
        const { soldAmount } = data
        return <Text>{currency(soldAmount, { precision: 0 }).format()}</Text>
      },
    },
  },
  {
    key: "soldDate",
    label: "Sold Date",
    isNotFormValue: true,
    required: false,
    Render: {
      Static: function ({ data }) {
        const { soldDate } = data
        return <Text>{dayjs(soldDate).format("MMM DD, YYYY")}</Text>
      },
    },
  },
  {
    key: "score",
    label: "Sell Score",
    isNotFormValue: true,
    required: false,
    Render: {
      Static: function ({ data }) {
        const { score } = data
        return <Text>{score}</Text>
      },
    },
  },
]
