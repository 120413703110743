import {Center, Group, Loader, Tabs, Text} from "@mantine/core"
import {RouteSegments, useUserRoutes} from "routes"
import {useNavigate, useParams} from "react-router-dom"
import {HomeDetails, HomeDocuments, HomeServices, HomeSettings} from "pages"
import {useEffect, useState} from "react"
import {HomeUtils, homeHooks} from "features/homes"
import {Calendar, Cards, Files, Id} from "tabler-icons-react"

export function HomeContent() {
    const {homeId, homeTab} = useParams()
    const [currentTab, setCurrentTab] = useState(homeTab)
    const navigate = useNavigate()
    const {routesResolver} = useUserRoutes()

    const {data: home, isLoading} = homeHooks.useGetById(
        parseInt(homeId || ""),
        {
            populate: "*",
        }
    )

    useEffect(() => {
        if (currentTab === RouteSegments.Today) {
            navigate(routesResolver.getHomeDetailsById(parseInt(homeId || "")))
        } else if (currentTab === RouteSegments.Services) {
            navigate(routesResolver.getHomeServicesById(parseInt(homeId || "")))
        } else if (currentTab === RouteSegments.Details) {
            navigate(routesResolver.getHomeSettingsById(parseInt(homeId || "")))
        } else if (currentTab === RouteSegments.Documents) {
            navigate(routesResolver.getHomeDocumentsByHomeId(parseInt(homeId || "")))
        }
    }, [currentTab])

    useEffect(() => {
        setCurrentTab(homeTab)
    }, [homeTab])

    if (isLoading)
        return (
            <Center>
                <Loader/>
            </Center>
        )

    return (
        <Tabs
            value={currentTab}
            onTabChange={(value: string) => setCurrentTab(value)}
            sx={(theme) => ({
                width: "100%",
                ".mantine-Tabs-tab": {
                    fontSize: 14,
                    letterSpacing: "0.01em",
                    "&[data-active]": {
                        fontWeight: 600,
                        ".mantine-Text-root": {
                            color: `${
                                theme.colorScheme === "dark"
                                    ? theme.colors.gray[0]
                                    : theme.colors.dark[9]
                            }`,
                        },
                        ".mantine-Tabs-tabIcon": {
                            color: `${theme.colors.blue[6]}`,
                        },
                    },
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                    "&:first-of-type": {
                        marginLeft: 0,
                    },
                },
            })}
        >
            <Tabs.List>
                <Tabs.Tab ml={0} value={RouteSegments.Today} icon={<Calendar size={18}/>}>
                    <Group spacing={6}>
                        <Text>Today</Text>
                    </Group>
                </Tabs.Tab>
                {!HomeUtils.isBeingSold(home?.data) && (
                    <Tabs.Tab value={RouteSegments.Services} icon={<Cards size={18}/>}>
                        <Group spacing={6}>
                            <Text>Services</Text>
                        </Group>
                    </Tabs.Tab>
                )}
                {HomeUtils.isBeingSold(home?.data) && (
                    <Tabs.Tab value={RouteSegments.Documents} icon={<Files size={18}/>}>
                        <Group spacing={6}>
                            <Text>Documents</Text>
                        </Group>
                    </Tabs.Tab>
                )}
                <Tabs.Tab value={RouteSegments.Details} icon={<Id size={18}/>}>
                    <Group spacing={6}>
                        <Text>Details</Text>
                    </Group>
                </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value={RouteSegments.Today} pt="xl">
                <HomeDetails/>
            </Tabs.Panel>
            {HomeUtils.isBeingSold(home?.data) && (
                <Tabs.Panel value={RouteSegments.Documents} pt="xs">
                    <HomeDocuments/>
                </Tabs.Panel>
            )}
            {!HomeUtils.isBeingSold(home?.data) && (
                <Tabs.Panel value={RouteSegments.Services} pt="xs">
                    <HomeServices/>
                </Tabs.Panel>
            )}
            <Tabs.Panel value={RouteSegments.Details} pt="xs">
                <HomeSettings/>
            </Tabs.Panel>
        </Tabs>
    )
}
