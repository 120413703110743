import { ModelApiService, ModelRoutes } from "services/strapi"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

export interface UpdateValuesType<ValuesType> {
  id: number
  values: ValuesType
}

export class ModelHooks<ModelType> {
  public modelApiService: ModelApiService<ModelType>

  constructor(modelApiService: ModelApiService<ModelType>) {
    this.modelApiService = modelApiService
  }

  get modelQueryKey() {
    return this.modelApiService.route
  }

  useGet = (query?: any, enabled?: boolean) => {
    return useQuery(
      [this.modelQueryKey, query],
      () => this.modelApiService.findMany(query),
      { enabled }
    )
  }

  useGetById = (id: number, query: any) => {
    return useQuery([this.modelQueryKey, id, query], () =>
      this.modelApiService.findOne(id, query)
    )
  }

  useGetBySlug = (slug: string, query: any) => {
    return useQuery([this.modelQueryKey, slug, query], () =>
      this.modelApiService.findOneBySlug(slug, query)
    )
  }

  useCreate = <ValuesType>(extraInvalidateKeys: ModelRoutes[] = []) => {
    const queryClient = useQueryClient()
    return useMutation(
      (values: ValuesType) => this.modelApiService.create<ValuesType>(values),
      {
        onSuccess: () =>
          queryClient.invalidateQueries([
            this.modelQueryKey,
            ...extraInvalidateKeys,
          ]),
      }
    )
  }

  useUpdate = <ValuesType>(
    extraInvalidateKeys: ModelRoutes[] = [],
    query?: any
  ) => {
    const queryClient = useQueryClient()
    return useMutation(
      (payload: UpdateValuesType<ValuesType>) =>
        this.modelApiService.update<ValuesType>(
          payload.id,
          payload.values,
          query
        ),
      {
        onSuccess: () =>
          queryClient.invalidateQueries([
            this.modelQueryKey,
            ...extraInvalidateKeys,
          ]),
      }
    )
  }

  useDelete = (extraInvalidateKeys: ModelRoutes[] = []) => {
    const queryClient = useQueryClient()
    return useMutation((id: number) => this.modelApiService.delete(id), {
      onSuccess: () =>
        queryClient.invalidateQueries([
          this.modelQueryKey,
          ...extraInvalidateKeys,
        ]),
    })
  }
}
