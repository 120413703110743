import {Anchor, Image, Skeleton, Stack, Text} from "@mantine/core"
import {NumberUtils} from "utils";
import ZillowLogo from "assets/Zillow_Wordmark_Blue_RGB lrg.png"

interface Props {
    zillowAvm?: number
    loading?: boolean
}

export function ZillowAvm({zillowAvm, loading}: Props) {
    function renderZillowAvm() {
        if (loading) {
            return <Skeleton height={72}/>
        } else if (!zillowAvm) {
            return (
                <Text style={{textAlign: "center"}}>
                    Your home's Zillow valuation is currently being generated, please check back
                    later.
                </Text>
            )
        } else {
            return (
                <Text size={30} weight={700}>
                    ${NumberUtils.numberWithCommas(zillowAvm)}
                </Text>
            )
        }
    }

    return (
        <Stack spacing="xs" align="center">
            <Text color="dimmed" size="xs">
                Provided via the Zestimate API
            </Text>
            {renderZillowAvm()}
            <Stack align="center" spacing={0}>
                <Text size="xs">Zestimate® home valuation</Text>
                <Anchor href="http://www.zillow.com">
                    <Image mt={12} width={100} src={ZillowLogo}/>
                </Anchor>
            </Stack>
        </Stack>
    )
}
