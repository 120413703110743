import { usePlacesWidget } from "react-google-autocomplete"
import { TextInput } from "@mantine/core"
import { AddressComponent } from "@google/maps"

interface Props {
  onChange?: (addressComponents: AddressComponent<any>[] | null) => void
}

export function GooglePlaces({ onChange }: Props) {
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyBtiVvAa2uyVzujUsVPO7zhN6tbV6yV6_w",
    onPlaceSelected: (place) => {
      if (onChange) {
        onChange(place.address_components as any)
      }
    },
    options: {
      types: ["address"],
      fields: ["address_components"],
      componentRestrictions: { country: "US" },
    },
  }) as any
  return (
    <TextInput
      placeholder="Search for your home's address"
      id={"get-offer-input"}
      ref={ref}
    />
  )
}
