import {Image, Skeleton, Stack, Text} from "@mantine/core"
import {NumberUtils} from "utils";
import AttomLogo from "assets/AttomData-logo.png"
import ZillowLogo from "assets/Zillow_Wordmark_Blue_RGB lrg.png";

interface Props {
    attomAvm?: number
    loading?: boolean
}

export function AttomAvm({attomAvm, loading}: Props) {
    function renderAttomAvm() {
        if (loading) {
            return <Skeleton height={72}/>
        } else if (!attomAvm) {
            return (
                <Text style={{textAlign: "center"}}>
                    Your home's ATTOM valuation is currently being generated, please check back
                    later.
                </Text>
            )
        } else {
            return (
                <Text size={30} weight={700}>
                    ${NumberUtils.numberWithCommas(attomAvm)}
                </Text>
            )
        }
    }

    return (
        <Stack spacing="xs" align="center">
            <Text color="dimmed" size="xs">
                Provided by ATTOM
            </Text>
            {renderAttomAvm()}
            <Image mt={12} width={100} src={AttomLogo}/>
        </Stack>
    )
}
