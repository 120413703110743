import {
  ActionIcon,
  Box,
  Button,
  Center,
  Group,
  Paper,
  Stack,
  Text,
  Textarea,
  Title,
} from "@mantine/core"
import { isNotEmpty, useForm } from "@mantine/form"
import { useModals } from "@mantine/modals"
import {
  IconArrowLeft,
  IconArrowRight,
  IconChevronDown,
  IconChevronUp,
} from "@tabler/icons"
import { Content } from "components/common"
import {
  HomeListingPaymentCheckout,
  ICreatePaymenetIntentValues,
  IHomeFormListingPaymentValues,
  homeHooks,
} from "features/homes"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useUserRoutes } from "routes"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import { Calendar } from "@mantine/dates"
import { useState } from "react"

export function HomeListingPaymentPage() {
  const modals = useModals()
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ""
  )

  const { homeId } = useParams()
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get("taskId")

  const navigate = useNavigate()
  const { routesResolver } = useUserRoutes()

  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [time, setTime] = useState({ hour: 9, minute: 0 })
  const handleHourChange = (value: number) => {
    setTime((prevTime) => ({
      ...prevTime,
      hour: value,
    }))
    form.setFieldValue("hour", value)
  }
  const handleMinuteChange = (value: number) => {
    setTime((prevTime) => ({
      ...prevTime,
      minute: value,
    }))
    form.setFieldValue("minute", value)
  }

  const { mutateAsync: createPaymentIntent } =
    homeHooks.useCreateHomeListingPaymentIntent()

  const minScheduledDate = () => {
    const today = new Date()
    const threeDaysFromNow = new Date(today)
    threeDaysFromNow.setDate(today.getDate() + 3)
    return threeDaysFromNow
  }

  const maxScheduledDate = () => {
    const today = new Date()
    const threeDaysFromNow = new Date(today)
    threeDaysFromNow.setDate(today.getDate() + 360)
    return threeDaysFromNow
  }

  const form = useForm<IHomeFormListingPaymentValues>({
    validate: {
      date: isNotEmpty("This field is required"),
    },
    validateInputOnChange: true,
    initialValues: {
      id: parseInt(homeId || ""),
      taskId: parseInt(taskId || ""),
      date: minScheduledDate(),
      hour: 12,
      minute: 0,
      comments: "",
    },
  })

  async function onOpenCheckout() {
    if (!homeId) return

    // Convert date and time to a single date object
    const date = new Date(form.values.date || "")
    let hour = form.values.hour
    if (hour < 9) hour += 12
    date.setHours(hour)
    date.setMinutes(form.values.minute)

    const paymentIntentValues: ICreatePaymenetIntentValues = {
      id: parseInt(homeId),
      taskId: parseInt(taskId || ""),
      date: date,
      comments: form.values.comments,
    }

    if (!form.validate().hasErrors) {
      setCheckoutLoading(true)
      const response = await createPaymentIntent(paymentIntentValues)
      if (response?.clientSecret) {
        modals.openModal({
          withCloseButton: true,
          closeOnClickOutside: false,
          closeOnEscape: false,
          centered: true,
          size: 500,
          overflow: "outside",
          title: <Text size={"xl"}>Home Listing Payment Checkout</Text>,
          children: (
            <Elements
              options={{
                clientSecret: response?.clientSecret || "",
              }}
              stripe={stripePromise}
            >
              <HomeListingPaymentCheckout taskId={taskId} homeId={homeId} />
            </Elements>
          ),
        })
      }
      setCheckoutLoading(false)
    }
  }

  function formatTime(hour: number) {
    const displayHour = hour > 12 ? hour - 12 : hour === 0 ? 12 : hour
    return displayHour
  }

  const displayMinute = () => {
    return time.minute < 10 ? `0${time.minute}` : time.minute
  }

  const displayAmPm = () => {
    return time.hour >= 9 && time.hour <= 11 ? "AM" : "PM"
  }

  return (
    <Content.Body>
      <Stack>
        <Box>
          <Button
            leftIcon={<IconArrowLeft />}
            onClick={() =>
              navigate(
                routesResolver.getHomeDetailsById(parseInt(homeId || ""))
              )
            }
          >
            Back
          </Button>
        </Box>
        <Title>Complete Home Listing Payment</Title>
        <Paper withBorder p={"md"}>
          <Stack spacing={"lg"}>
            <Text size="md">
              Ready to put your home on the market? Our comprehensive listing
              package makes it simple and stress-free. For $500, you'll receive:
            </Text>
            <Stack spacing={0}>
              <Title size={"lg"}>Professional Home Photos</Title>
              <Text color="dimmed" size="md" span>
                Captivate buyers with high-quality images that showcase your
                property’s best features. Our expert photographers ensure every
                room shines.
              </Text>
            </Stack>
            <Stack spacing={0}>
              <Title size={"lg"}>Custom For Sale Sign</Title>
              <Text color="dimmed" size="md" span>
                Make your home stand out with a personalized for sale sign,
                professionally created and delivered to your doorstep.
              </Text>
            </Stack>
            <Stack spacing={0}>
              <Title size={"lg"}>Secure Key Lockbox</Title>
              <Text color="dimmed" size="md" span>
                Provide easy and secure access to your home for potential buyers
                and agents. We deliver a sturdy key lockbox directly to your
                property.
              </Text>
            </Stack>
            <form>
              <Stack spacing={"lg"}>
                <Text size="md" span>
                  Please provide a preferred date and time for our team to come
                  out and take photos of your property and to drop off your For
                  Sale sign and key lockbox. We will contact you to confirm the
                  date and time.
                </Text>
                <Center>
                  <Stack>
                    <Center>
                      <Text>Preferred Date</Text>
                    </Center>
                    <Calendar
                      minDate={minScheduledDate()}
                      maxDate={maxScheduledDate()}
                      {...form.getInputProps("date")}
                    />
                    {form.errors.date && (
                      <Text color="red" size="sm">
                        *Please select a preferred date
                      </Text>
                    )}

                    <Stack spacing={0}>
                      <Center>
                        <Text>Preferred Time</Text>
                      </Center>
                      <Center>
                        <Text color="dimmed" size={"sm"}>
                          *Choose a time from 9am to 5pm
                        </Text>
                      </Center>
                    </Stack>

                    <Center>
                      <Group>
                        <Stack>
                          <ActionIcon
                            onClick={() => {
                              const minTime = 9 // 9 AM
                              const maxTime = 5 // 5 PM

                              let currentHour = time.hour

                              if (
                                currentHour >= maxTime &&
                                currentHour < minTime
                              ) {
                                currentHour = minTime
                              } else {
                                currentHour++
                              }

                              const newTime = formatTime(currentHour)

                              return handleHourChange(newTime)
                            }}
                          >
                            <IconChevronUp />
                          </ActionIcon>
                          <Center>
                            <Text>{time.hour.toString()}</Text>
                          </Center>
                          <ActionIcon
                            onClick={() => {
                              const minTime = 5 // 5 PM
                              const maxTime = 9 // 9 AM

                              let currentHour = time.hour

                              if (
                                currentHour <= maxTime &&
                                currentHour > minTime
                              ) {
                                currentHour = minTime
                              } else {
                                currentHour--
                              }

                              const newTime = formatTime(currentHour)

                              return handleHourChange(newTime)
                            }}
                          >
                            <IconChevronDown />
                          </ActionIcon>
                        </Stack>
                        <Text>:</Text>
                        <Stack>
                          <ActionIcon
                            onClick={() =>
                              handleMinuteChange((time.minute + 30) % 60)
                            }
                          >
                            <IconChevronUp />
                          </ActionIcon>
                          <Center>
                            <Text>{displayMinute()}</Text>
                          </Center>
                          <ActionIcon
                            onClick={() =>
                              handleMinuteChange((time.minute + 60) % 60)
                            }
                          >
                            <IconChevronDown />
                          </ActionIcon>
                        </Stack>
                        <Stack>
                          <Center>
                            <Text>{displayAmPm()}</Text>
                          </Center>
                        </Stack>
                      </Group>
                    </Center>
                  </Stack>
                </Center>
                <Textarea
                  label="Comments or additional instructions for our team"
                  {...form.getInputProps("comments")}
                />
              </Stack>
              <Stack mt={"lg"} spacing={0}>
                <Button
                  rightIcon={<IconArrowRight />}
                  mt={"lg"}
                  loading={checkoutLoading}
                  disabled={checkoutLoading}
                  onClick={onOpenCheckout}
                >
                  Open Checkout
                </Button>
              </Stack>
            </form>
          </Stack>
        </Paper>
      </Stack>
    </Content.Body>
  )
}
