import {Button, Group, Stack, Text, CopyButton} from "@mantine/core";
import {openModal, closeModal} from '@mantine/modals';
import {UserInviteForm} from "./user.invite.form";
import {CircleCheck, Send} from "tabler-icons-react";

interface Props {
    onSubmitQuery: (values: any) => any,
    roles: string[],
}

export function UserInviteButton({onSubmitQuery, roles}: Props) {
    async function _onSubmitQuery(values: any) {
        const res = await onSubmitQuery(values);
        closeModal("initialModal");
        if (res.errors) {
            openModal({
                modalId: "errorModal",
                title: (
                    <Group>
                        <CircleCheck color="red"/>
                        <Text color="white">Error Inviting User</Text>
                    </Group>
                ),
                children: (
                    <Stack>
                        <Text span>
                            There was an error inviting user with email <Text span weight={600} color="red">{values.email}</Text>.
                        </Text>
                        <Text>
                            Error: {res.errors.error.message}
                        </Text>
                    </Stack>
                ),
                centered: true,
            })
        } else {
            openModal({
                modalId: "successModal",
                title: (
                    <Group>
                        <CircleCheck color="green"/>
                        <Text color="white">Invitation Sent</Text>
                    </Group>
                ),
                children: (
                    <Stack>
                        <Text span>
                            You have successfully invited <Text span weight={600} color="green">{values.email}</Text> as
                            a <Text span weight={600} color="green">{values.roleName}</Text>. They will receive an
                            invite link via email. Press the Copy Invitation Link button below if you'd like to invite
                            them manually.
                        </Text>
                        <CopyButton
                            value={`${window.location.origin}/auth/accept-invitation?invitationToken=${res.invitationToken}`}>
                            {({copied, copy}) => (
                                <Button color={copied ? 'teal' : 'blue'} onClick={copy}>
                                    {copied ? 'Copied Link' : 'Copy Invitation Link'}
                                </Button>
                            )}
                        </CopyButton>
                    </Stack>

                ),
                centered: true,
            })
        }
    }

    return (
        <Button leftIcon={<Send size={18}/>} onClick={() => {
            openModal({
                modalId: "initialModal",
                title: 'Invite a User',
                children: <UserInviteForm onSubmitQuery={_onSubmitQuery} roles={roles}/>,
                centered: true,
            });
        }}>
            Invite User
        </Button>
    )
}
