import {ReactComponent as NoDataSVG} from "assets/undraw/undraw_empty_street.svg"
import {Stack, Text} from "@mantine/core";

interface Props {
    title: string,
    description?: string,
    action?: any,
}

export function NoData({
    title,
    description,
    action
}: Props) {
    return (
        <Stack spacing={0} py={24} align="center">
            <NoDataSVG width={300} height={100}/>
            <Stack spacing={0} mt={18} style={{
                maxWidth: 500,
                textAlign: "center"
            }}>
                <Text size={18} gradient={{
                    from: 'red',
                    to: 'orange',
                    deg: 45
                }} weight={600} variant="gradient">
                    {title}
                </Text>
                {description &&
                    <Text size={14} mt={6} color="dimmed">
                        {description}
                    </Text>}
            </Stack>
            {action &&
                <Stack mt={16}>
                    {action}
                </Stack>}
        </Stack>
    )
}
