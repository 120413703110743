import {
  ModelRoutes,
  ModelApiService,
  IStrapiResponse,
  IStrapiEntity,
} from "services/strapi"
import { IDocument } from "features/documents"
import { strapi } from "lib/strapi-api"
import axios from "axios"

class DocumentApiSingleton extends ModelApiService<IDocument> {
  create = async (values: any) => {
    try {
      const formData = new FormData()
      const data = {
        home: values.home,
        folderId: values.folderId,
      }

      formData.append(`files.file`, values.file, values.file.name)

      formData.append("data", JSON.stringify(data))

      const res = await strapi.post(`/${this.route}`, formData)
      if (res) {
        return res.data as IStrapiResponse<IStrapiEntity<IDocument>>
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error("Error creating document", e.message)
      }
      return undefined
    }
  }
}

export const documentApi = new DocumentApiSingleton(ModelRoutes.Documents)
