import { Content, Page } from "components/common"
import { DefaultPageWrapper } from "layouts"

export function AdminDashboard() {
  return (
    <DefaultPageWrapper>
      <Page.Header>
        <Page.Title title="Admin Dashboard" />
      </Page.Header>
      <Page.Body>
        <Content.Section>
          <Content.Header>
            <Content.Title>Users</Content.Title>
          </Content.Header>
        </Content.Section>
      </Page.Body>
    </DefaultPageWrapper>
  )
}
