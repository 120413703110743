export class StringUtils {
  static capitalizeFirstLetter(string?: string) {
    if (!string) return
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  static isNullOrWhiteSpace(str: string | null | undefined) {
    return !str || str.length === 0 || /^\s*$/.test(str)
  }
}
