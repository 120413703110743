import {Title, Stack, Paper} from "@mantine/core"
import {Content} from "components/common"
import {
    DocumentFoldersFolderView,
    IDocumentFolder,
    documentFolderHooks,
} from "features/document-folders"
import {DocumentsFolderView, documentHooks} from "features/documents"
import {useState} from "react"
import {useParams} from "react-router-dom"
import {IStrapiEntity} from "services/strapi"

export function HomeDocuments() {
    const {homeId} = useParams()

    const [selectedFolder, setSelectedFolder] =
        useState<IStrapiEntity<IDocumentFolder> | null>(null)

    const {isLoading: isLoadingDocumentFolders, data: documentFolders} =
        documentFolderHooks.useGet({})

    const {isLoading: isLoadingDocuments, data: documents} =
        documentHooks.useGet({
            filters: {
                home: homeId,
                folderId: selectedFolder?.attributes.folderId,
            },
            populate: "*",
        })

    return (
        <Content.Body>
            <Stack>
                <Title order={2}>Documents</Title>
                {!selectedFolder && (
                    <Paper withBorder>
                        <DocumentFoldersFolderView
                            data={documentFolders?.data}
                            loading={isLoadingDocumentFolders}
                            onFolderClick={(folder) => setSelectedFolder(folder)}
                        />
                    </Paper>
                )}
                {selectedFolder && (
                    <DocumentsFolderView
                        loading={isLoadingDocuments}
                        data={documents?.data}
                        onBackClick={() => setSelectedFolder(null)}
                        folder={selectedFolder}
                        homeId={homeId}
                    />
                )}
            </Stack>
        </Content.Body>
    )
}
