import { Select, SelectProps, Text } from "@mantine/core"
import { homeHooks } from "features/homes"

interface Props extends Omit<SelectProps, "data"> {
  filters?: any
  form?: any // Pass form when entities need to be disabled as they are picked in a list table
}

export function HomesSelect({ filters, ...rest }: Props) {
  const { isLoading, data: homes } = homeHooks.useGet({
    filters: {
      ...filters,
    },
  })

  const homesData = homes?.data
    ? homes.data
        ?.sort((a, b) => {
          const nameA = a.id
          const nameB = b.id

          if (nameA < nameB) {
            return -1
          }

          if (nameA > nameB) {
            return 1
          }

          return 0 // names are equal
        })
        .map((p) => {
          return {
            value: p.id.toString(),
            label: p.attributes.streetAddress,
          }
        })
    : []

  return (
    <Select
      searchable
      nothingFound={<Text>No Homes to select</Text>}
      data={homesData}
      disabled={isLoading || rest.disabled}
      w={300}
      {...rest}
    />
  )
}
