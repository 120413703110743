import {QueryManagerProps, QueryManager} from "utils";
import {useState} from "react";

export function useQueryManager<ValuesType>(config: QueryManagerProps) {
    const queryManager = new QueryManager(config);

    const [query, setQuery] = useState<any>(
        config.localStorageKey ? queryManager.getQueryFromLocalStorage()
            .getQsMappedValues() : undefined
    );

    function onFilter(values: ValuesType) {
        const serializedQuery =
            queryManager.setDeserializedValues(values)
                .serializeQuery()
                .setQueryToLocalStorage()
                .setInitialQuerySent()
                .getQsMappedValues();
        setQuery(serializedQuery);
    }

    function getInitialValues() {
        return queryManager.setSerializedValues(
            queryManager.getQueryFromLocalStorage()
                .getValues()
        )
            .deserializeQuery()
            .getValues()
    }

    return {
        query,
        setQuery,
        onFilter,
        getInitialValues,
        queryManager
    }
}
