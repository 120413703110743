import {
  Title,
  Stack,
  Grid,
  Card,
  Text,
  Button,
  Center,
  Container,
  Group,
  Box,
  useMantineTheme,
} from "@mantine/core"
import { IconCheck } from "@tabler/icons"
import { Content } from "components/common"
import { ETaskFormTypes } from "features/tasks"
import { useNavigate, useParams } from "react-router-dom"
import { useUserRoutes } from "routes"

export function HomeServices() {
  const { homeId } = useParams()
  const theme = useMantineTheme()
  const navigate = useNavigate()
  const { routesResolver } = useUserRoutes()

  return (
    <Content.Body>
      <Stack mt={24}>
        <Title order={1} style={{ textAlign: "center" }}>
          Explore Our Solutions
        </Title>
        <Stack mb={24} align="center" justify="center">
          <Text maw={600} style={{ textAlign: "center" }}>
            Discover a range of tailored services designed to meet your unique
            needs and exceed your expectations.
          </Text>
        </Stack>
        <Container>
          <Grid gutter={"xl"} style={{ minHeight: "650px" }}>
            <Grid.Col sm={6}>
              <Card withBorder h={"100%"} p={"xl"}>
                <Stack spacing={"xl"} h={"100%"}>
                  <Stack h={"150px"}>
                    <Title order={1}>Cash Offer 💰</Title>
                    <Text weight={600} size={24}>
                      Fastest Way To Sell
                    </Text>
                  </Stack>

                  <Box h={"125px"}>
                    <Text>
                      Get multiple hassle-free cash offers for your home from
                      trusted cash buyers—no listing required.
                    </Text>
                  </Box>

                  <Stack>
                    <Group>
                      <IconCheck color={theme.colors.green[6]} />{" "}
                      <Text fw={600}>Avoid Listing Hassles</Text>
                    </Group>
                    <Group>
                      <IconCheck color={theme.colors.green[6]} />{" "}
                      <Text fw={600}>Pick From Multiple Offers</Text>
                    </Group>
                    <Group>
                      <IconCheck color={theme.colors.green[6]} />{" "}
                      <Text fw={600}>
                        We Protect Your Interests In The Transaction
                      </Text>
                    </Group>
                  </Stack>

                  <Center style={{ marginTop: "auto" }}>
                    <Button
                      size="lg"
                      fullWidth
                      // onClick={() =>
                      //   navigate(
                      //     routesResolver.getInteractiveFormByHomeId(
                      //       parseInt(homeId || ""),
                      //       ETaskFormTypes.CASH_OFFER
                      //     )
                      //   )
                      // }
                      disabled={true}
                    >
                      Coming Soon
                    </Button>
                  </Center>
                </Stack>
              </Card>
            </Grid.Col>
            <Grid.Col sm={6}>
              <Card withBorder h={"100%"} p={"xl"}>
                <Stack spacing={"xl"} h={"100%"}>
                  <Stack h={"150px"}>
                    <Title order={1}>Simple Sale 🎯</Title>
                    <Text weight={600} size={24}>
                      $5,000 flat fee
                    </Text>
                  </Stack>

                  <Box h={"125px"}>
                    <Text>
                      Leverage our tech platform and on-demand guidance for a
                      seamless, cost-effective selling experience. Plus, we’re
                      here to help you find and purchase your next home.
                    </Text>
                  </Box>

                  <Stack>
                    <Group>
                      <IconCheck color={theme.colors.green[6]} />{" "}
                      <Text fw={600}>Save Thousands On Commissions</Text>
                    </Group>
                    <Group>
                      <IconCheck color={theme.colors.green[6]} />{" "}
                      <Text fw={600}>Access Expert Guidance On-Demand</Text>
                    </Group>
                    <Group>
                      <IconCheck color={theme.colors.green[6]} />{" "}
                      <Text fw={600}>Maximum Efficiency & Transparency</Text>
                    </Group>
                  </Stack>

                  <Center style={{ marginTop: "auto" }}>
                    <Button
                      size="lg"
                      fullWidth
                      onClick={() =>
                        navigate(
                          routesResolver.getInteractiveFormByHomeId(
                            parseInt(homeId || ""),
                            ETaskFormTypes.SIMPLE_SALE
                          )
                        )
                      }
                    >
                      Get Started
                    </Button>
                  </Center>
                </Stack>
              </Card>
            </Grid.Col>
          </Grid>
        </Container>
      </Stack>
    </Content.Body>
  )
}
