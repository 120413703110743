import {Timeline, Text, Stepper, Loader, useMantineTheme} from "@mantine/core"
import { IStrapiEntity } from "services/strapi"
import { IHome } from "features/homes/types"
import { ITask } from "features/tasks"
import { useMemo } from "react"
import { ITaskStep } from "features/task-steps/types"
import {Circle, CircleCheck, CircleDot, CircleDotted} from "tabler-icons-react";

interface Props {
  home?: IStrapiEntity<IHome>
  tasks?: IStrapiEntity<ITask>[]
  taskSteps?: IStrapiEntity<ITaskStep>[]
}

export function ClosingStepsTimeline({ home, tasks, taskSteps }: Props) {
  const theme = useMantineTheme()
  const sortedTaskSteps = useMemo(
    () =>
      taskSteps?.sort(
        (a, b) => (a.attributes.sequence || 0) - (b.attributes.sequence || 0)
      ),
    [taskSteps]
  )

  if (!home) {
    return <></>
  }

  if (!tasks) return <></>

  function getActiveIndex() {
    if (!tasks || !sortedTaskSteps) return -1

    // Show timeline as completed if all closing tasks are completed
    if (
      tasks
        .filter((t) => t.attributes.stepId === "closing")
        .every((t) => t.attributes.completedOn)
    )
      return sortedTaskSteps.length - 1

    // Get the index of the earliest step (step with lowest sequence) where any of its corresponding tasks are not completed
    let activeIndex = 0
    for (let i = 0; i < sortedTaskSteps.length; i++) {
      const taskStep = sortedTaskSteps[i]
      const incomplete = tasks.find(
        (t) =>
          t.attributes.stepId === taskStep.attributes.stepId &&
          !t.attributes.completedOn
      )
      if (incomplete) {
        activeIndex = i
        break
      }
    }

    return activeIndex
  }

  function getColorFromIndex(index:number){
    const activeIndex = getActiveIndex()
    if (activeIndex === index){
      return theme.colors["blue"][8]
    } else if (index < activeIndex){
      return theme.colors["green"][8]
    } else {
      return theme.colors["gray"][5]
    }
  }

  return (
    <Stepper color="green" size="xs" iconSize={18} active={getActiveIndex()} sx={{
      ".mantine-Stepper-stepIcon": {
        backgroundColor:"transparent !important",
        border: "none"
      },
      ".mantine-Stepper-steps": {
        flexWrap:"wrap"
      }
    }}>
      {sortedTaskSteps?.map((step, index) => (
        <Stepper.Step completedIcon={<CircleCheck size={18} color={theme.colors.green[8]}/>} progressIcon={<Loader size="xs"/>} icon={<Circle size={18}/>} allowStepSelect={false}
          key={index}
          label={
            <Text size="sm" fw={600} color={getColorFromIndex(index)}>
              {step.attributes.title}
            </Text>
          }
        ></Stepper.Step>
      ))}
    </Stepper>
  )
}
