export enum ModelRoutes {
  /* Generated Model Routes */
  EmbeddedDocuments = "embedded-documents",
  TaskSteps = "task-steps",
  DocumentFolders = "document-folders",
  Documents = "documents",
  Offers = "offers",
  Concierges = "concierges",
  Tasks = "tasks",
  Homes = "homes",
  UserData = "user-data",
  Users = "users",
}
