import {ListItemsTable, ListItemsTableProps} from "components/common"
import {IHomeRecentlySoldNear, RecentlySoldTableSettings} from "features/homes"
import {useUserRoutes} from "routes";

interface Props extends Omit<ListItemsTableProps<
    IHomeRecentlySoldNear,
    void>,
    'settings' | 'data'> {

    data?: IHomeRecentlySoldNear[]
}

export function RecentlySoldListTable({data, ...rest}: Props){
    const {routesResolver} = useUserRoutes()
    return (
        <ListItemsTable
            {...rest}
            data={data}
            settings={RecentlySoldTableSettings({
                routesResolver,
            })}
        />
    )
}