import { Outlet } from "react-router-dom"
import { Content } from "components/common"

export function HomePage() {

  return (
    <Content.Section sx={{ border: "none", background: "transparent" }}>
      <Outlet />
    </Content.Section>
  )
}
