import { ITask } from "features/tasks"

import { Button } from "@mantine/core"
import { IStrapiEntity } from "services/strapi"
import { IHome } from "features/homes"
import { IconFile } from "@tabler/icons"
import { useNavigate } from "react-router-dom"
import { useUserRoutes } from "routes"

interface Props {
  home?: IStrapiEntity<IHome>
  task?: IStrapiEntity<ITask>
}

export function TaskSignDocumentButton({ home, task }: Props) {
  const navigate = useNavigate()
  const { routesResolver } = useUserRoutes()
  if (!task || !home) return null

  function onClick() {
    if (!home || !task) return

    navigate(
      routesResolver.getEmbeddedDocumentByHomeAndTaskId(home.id, task.id)
    )
  }

  return (
    <Button leftIcon={<IconFile />} onClick={onClick}>
      Sign Document
    </Button>
  )
}
