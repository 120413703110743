import { ITask } from "features/tasks"

import { Button, Text } from "@mantine/core"
import { IconCalendar } from "@tabler/icons"
import { IStrapiEntity } from "services/strapi"
import { IHome } from "features/homes"
import { InlineWidget, useCalendlyEventListener } from "react-calendly"
import { useModals } from "@mantine/modals"

interface Props {
  home?: IStrapiEntity<IHome>
  task?: IStrapiEntity<ITask>
  onTaskComplete?: () => void
}

export function TaskCalendlyButton({ task, home, onTaskComplete }: Props) {
  const modals = useModals()

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      e.preventDefault()
      if (onTaskComplete) onTaskComplete()
    },
  })

  if (!task) return null

  function onClick() {
    modals.openModal({
      withCloseButton: true,
      closeOnClickOutside: true,
      closeOnEscape: true,
      centered: true,
      overflow: "outside",
      size: 1200,
      title: <Text size={"xl"}>Schedule a Meeting with BeHoused</Text>,
      children: (
        <InlineWidget
          url="https://calendly.com/dan-6soa/intro-call"
          prefill={{
            email: home?.attributes.user?.data?.attributes.email,
            firstName: home?.attributes.user?.data?.attributes.firstName,
            lastName: home?.attributes.user?.data?.attributes.lastName,
            name: `${home?.attributes.user?.data?.attributes.firstName} ${home?.attributes.user?.data?.attributes.lastName}`,
            smsReminderNumber:
              home?.attributes.user?.data?.attributes.phoneNumber,
          }}
        />
      ),
    })
  }

  return (
    <Button leftIcon={<IconCalendar />} onClick={onClick}>
      Schedule via Calendly
    </Button>
  )
}
