import { StrapiAuthApi } from "./auth.api"
import { strapi } from "lib/strapi-api"
import { useMutation, useQuery } from "@tanstack/react-query"
import { JWT_LS_KEY } from "settings"
import {
  IStrapiForgotPasswordValues,
  IStrapiResetPasswordValues,
} from "./types"
import { showNotification } from "@mantine/notifications"
import { ArrowRight, CircleCheck } from "tabler-icons-react"
import { Button, Stack, Text } from "@mantine/core"
import { RoutesResolver } from "routes"

export class StrapiAuthHooks {
  strapiAuthApi
  meQuery

  constructor(strapiAuthApi: StrapiAuthApi<any>, meQuery: any) {
    this.strapiAuthApi = strapiAuthApi
    this.meQuery = meQuery
  }

  get meQueryKey() {
    return ["me", this.meQuery]
  }

  private me = async () => {
    const res = await this.strapiAuthApi.me(this.meQuery)
    if (res) {
      return res
    } else {
      return undefined
    }
  }

  useLogout = () => {
    return () => {
      strapi.defaults.headers.common["Authorization"] = ""
      localStorage.removeItem(JWT_LS_KEY)
      window.location.href = "/"
    }
  }

  useForgotPassword = () => {
    return useMutation(
      async (values: IStrapiForgotPasswordValues) => {
        return await this.strapiAuthApi.forgotPassword(values)
      },
      {
        onSuccess: () => {
          showNotification({
            title: "Success!",
            message:
              "If your email exists, you will receive a password reset email.",
            color: "green",
          })
        },
      }
    )
  }

  useResetPassword = () => {
    return useMutation(
      async (values: IStrapiResetPasswordValues) => {
        return await this.strapiAuthApi.resetPassword(values)
      },
      {
        onSuccess: () => {
          const routesResolver = new RoutesResolver()
          showNotification({
            title: "Success!",
            autoClose: false,
            message: (
              <Stack>
                <Text>
                  Your password has been successfully reset. Click here to
                  navigate to the login page.
                </Text>
                <Button
                  rightIcon={<ArrowRight size={18} />}
                  onClick={() => window.location.replace(routesResolver.login)}
                >
                  Back to login page
                </Button>
              </Stack>
            ),
            color: "green",
            icon: <CircleCheck size={18} />,
          })
        },
      }
    )
  }

  useMe = () => {
    return useQuery(
      this.meQueryKey,
      async () => {
        return await this.me()
      },
      // If you set a stale time of 0, anytime a component renders under another component using
      // this function, you'll end up making duped calls. Since we only use this call at app
      // load and sparingly otherwise, we can set the stale time to 1 second.
      { staleTime: 1000 }
    )
  }
}
