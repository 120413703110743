import { Button, Group, Stack } from "@mantine/core"
import { IconUpload } from "@tabler/icons"
import { FileDropzone } from "components/common"
import { documentHooks } from "features/documents/hooks"
import { useState } from "react"

interface Props {
  homeId?: string | number
  folderId?: string
  onUpload?: () => void
}

export function DocumentsFileUploadForm({ homeId, folderId, onUpload }: Props) {
  const [files, setFiles] = useState<File[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const { mutateAsync, isLoading: uploading } = documentHooks.useCreate()

  const onFileChange = (values: File[]) => {
    const updatedFiles = [...files, ...values]
    setFiles(updatedFiles)
  }

  const onFileRemove = (index: number) => {
    const updatedFiles = [...files]
    updatedFiles.splice(index, 1)
    setFiles(updatedFiles)
  }

  async function onUploadClick() {
    setLoading(true)
    if (homeId && folderId) {
      const uploadPromises = []
      for (const file of files) {
        uploadPromises.push(mutateAsync({ file, home: homeId, folderId }))
      }
      await Promise.all(uploadPromises)
      if (onUpload) onUpload()
    }
    setLoading(false)
  }

  return (
    <Stack>
      <FileDropzone
        value={files}
        onChange={onFileChange}
        onRemove={onFileRemove}
        height={200}
      />
      <Group position={"right"}>
        <Button
          rightIcon={<IconUpload />}
          onClick={onUploadClick}
          loading={loading || uploading}
          disabled={files.length === 0}
        >
          Upload Files!
        </Button>
      </Group>
    </Stack>
  )
}
