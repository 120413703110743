import {Page} from "components/common";
import {DefaultPageWrapper} from "layouts";
import {homeHooks} from "features/homes";
import {useCurrentUser} from "hooks";
import {HomesSelect} from "features/homes/components/homes.select";
import {useEffect} from "react";
import {Outlet, useNavigate, useParams, useLocation} from "react-router-dom";
import {ActionIcon, Group, Skeleton, Tooltip, Text, Title} from "@mantine/core";
import {Plus} from "tabler-icons-react";
import {useUserRoutes} from "routes";

export function Dashboard() {
    const {user} = useCurrentUser()
    const location = useLocation()
    const navigate = useNavigate()
    const {homeId} = useParams()
    const {routesResolver} = useUserRoutes()
    const {data: homes, isLoading: isLoadingHomes} = homeHooks.useGet({
        filters: {
            user: user?.id
        },
        sort: "createdAt:asc",
        populate: "*"
    })
    const {isLoading: isLoadingHome, data: home} = homeHooks.useGetById(
        parseInt(homeId || ""),
        {
            populate: "*",
        }
    )
    const isCreatePage = location.pathname === routesResolver.homeCreate
    useEffect(() => {
        if (homes?.data && homes.data.length > 0 && !homeId && !isCreatePage) {
            navigate(homes.data[0].id.toString(), {replace: true})
        } else if (homes?.data && homes.data.length === 0) {
            navigate(routesResolver.homeCreate, {replace: true})
        }
    }, [homes, homeId, isCreatePage])

    function onChangeHome(homeId: string) {
        navigate(homeId)
    }

    return (
        <DefaultPageWrapper>
            {!isCreatePage &&
                <Page.Header position="apart">
                    {isLoadingHome ? (
                        <Skeleton height={24} w={300}/>
                    ) : (
                        <Title order={2}>{home?.data.attributes.streetAddress}</Title>
                    )}
                    <Group>
                        <HomesSelect clearable={false} disabled={isLoadingHomes} value={homeId}
                                     onChange={onChangeHome}/>
                        <Tooltip label="Add a Home">
                            <ActionIcon color="blue" onClick={() => navigate(routesResolver.homeCreate)}>
                                <Plus size={18}/>
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                </Page.Header>}
            <Page.Body>
                <Outlet/>
            </Page.Body>
        </DefaultPageWrapper>
    )
}