import React, {useState} from 'react';
import {Button, Grid, TextInput, Select} from '@mantine/core';
import {useForm} from '@mantine/form';

interface Props {
    onSubmitQuery?: (query: any) => void,
    initialValues?: any,
    roles: string[],
}

interface FormValues {
    fullName: string,
    email: string,
    roleName: string,
    client?: number
}

export function UserInviteForm({onSubmitQuery, initialValues, roles}: Props) {
    const [inviting, setInviting] = useState(false);

    const form = useForm<FormValues>({
        initialValues: initialValues || {
            fullName: "",
            email: "",
            roleName: roles[0],
        },
        validate: {},
    });

    async function onSubmit(values: typeof form.values) {
        if (!values.client) delete values.client;
        if (onSubmitQuery) {
            setInviting(true);
            await onSubmitQuery(values);
            setInviting(false);
        }
    }

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Grid>
                <Grid.Col span={12}>
                    <TextInput label="Full Name" placeholder="Full Name" required
                               {...form.getInputProps("fullName")}/>
                </Grid.Col>
            </Grid>
            <TextInput label="Email" placeholder="user@company.com" required mt="md"
                       {...form.getInputProps("email")}/>
            <Select label="Role" required mt="md" data={roles.map(role => ({label: role, value: role}))}
                    {...form.getInputProps("roleName")}/>
            <Button type="submit" fullWidth mt="xl" loading={inviting}>
                Invite User
            </Button>
        </form>
    )
}
