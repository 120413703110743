import { Button, Group, Paper } from "@mantine/core"
import { IconArrowLeft } from "@tabler/icons"
import { Content } from "components/common"
import { RecentlySoldListTable, homeHooks } from "features/homes"
import { useNavigate, useParams } from "react-router-dom"
import { useUserRoutes } from "routes"

export function HomeRecentlySoldNearYou() {
  const { homeId } = useParams()
  const navigate = useNavigate()

  const { routesResolver } = useUserRoutes()

  const { isLoading: isLoadingRecentlySold, data: home } =
    homeHooks.useGetById(parseInt(homeId!), {})

  return (
    <>
      <Content.Header>
        <Content.Title>
          <Group>
            <Button
              leftIcon={<IconArrowLeft />}
              onClick={() =>
                navigate(routesResolver.getHomeDetailsById(homeId || ""))
              }
            >
              Back
            </Button>
            Recently Sold Near You
          </Group>
        </Content.Title>
      </Content.Header>
      <Content.Body>
        <Paper withBorder>
          <RecentlySoldListTable
            disableFields={["daysOnMarket", "score"]}
            data={home?.data.attributes.recentSolds
              ?.filter((home) => home.soldAmount > 0)
              .sort(
                (a, b) =>
                  new Date(b.soldDate).getTime() -
                  new Date(a.soldDate).getTime()
              )}
            loading={isLoadingRecentlySold}
          />
        </Paper>
      </Content.Body>
    </>
  )
}
