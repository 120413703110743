import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import { userApi } from "../services/user.strapi.api";

interface UpdateUserPayload<ValuesType> {
    id:number,
    values:ValuesType
}

class UserApiHooks {
    useUsers = (query: any) => {
        return useQuery(
            ["fetchUsers", query],
            () => userApi.getUsers(query)
        );
    }

    useGetInvitationRolePermissions = (roleName: string) => {
        return useQuery(
            ["fetchInvitationRolePermissions", roleName],
            () => userApi.getUserRoleInvitationPermissions(roleName)
        );
    }

    useGetUserAvatars = (userIds: number[]) => {
        return useQuery(
            ["fetchUserAvatars", userIds],
            () => userApi.getUserAvatars(userIds)
        );
    }

    useGetByClerkUserId = (clerkUserId?: string) => {
        return useQuery(
            ["user", clerkUserId],
            () => userApi.getUserByClerkUserId(clerkUserId)
        );
    }

    useMutateInviteUser = () => {
        const queryClient = useQueryClient();
        return useMutation(userApi.inviteUser, {
            onSuccess: () => {
                queryClient.invalidateQueries(["fetchUsers"])
            },
        })
    }

    useUpdateUser = (query?: any, extraInvalidateKeys?: string[]) => {
    const queryClient = useQueryClient()
    return useMutation(
      (payload: UpdateUserPayload<any>) =>
        userApi.updateUser(payload.id, payload.values, query),
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(["fetchUsers"])
          if (extraInvalidateKeys) {
            for (let i = 0; i < extraInvalidateKeys.length; i++) {
              const extraInvalidateKey = extraInvalidateKeys[i]
              await queryClient.invalidateQueries([extraInvalidateKey])
            }
          }
        },
      }
    )
  }
}

export const userHooks = new UserApiHooks();
