import { ICoOwner, IHome, IHomeFormValues } from "features/homes"
import { IStrapiEntity } from "services/strapi"
import { isEmail, isNotEmpty, matches } from "@mantine/form"

export class HomeUtils {
  static transformInitialValues(home?: IStrapiEntity<IHome>): IHomeFormValues {
    if (!home) {
      return {}
    } else {
      const { ...rest } = home.attributes
      return {
        id: home.id,
        ...rest,
        yearBuilt: rest.yearBuilt
          ? (() => {
              try {
                return parseInt(rest.yearBuilt as string)
              } catch (error) {
                return undefined
              }
            })()
          : undefined,
        floors: rest.floors
          ? (() => {
              try {
                return parseInt(rest.floors as string)
              } catch (error) {
                return undefined
              }
            })()
          : undefined,
        parkingSpaces: rest.parkingSpaces
          ? (() => {
              try {
                return parseInt(rest.parkingSpaces as string)
              } catch (error) {
                return undefined
              }
            })()
          : undefined,
        hasHoa: rest.hasHoa ? "true" : "false",
        hasSolar: rest.hasSolar ? "true" : "false",
      }
    }
  }

  static transformCoOwnerInitialValues(co?: IStrapiEntity<ICoOwner>): ICoOwner {
    if (!co) {
      return {
        phoneNumber: "",
        emailAddress: "",
        firstName: "",
        lastName: "",
        fullName: "",
      }
    } else {
      const { ...rest } = co.attributes
      return {
        id: co.id,
        ...rest,
      }
    }
  }

  static getValidationSchema(): Partial<Record<keyof IHomeFormValues, any>> {
    return {}
  }

  static getCoOwnersValidationSchema(): Partial<Record<keyof ICoOwner, any>> {
    return {
      emailAddress: isEmail(),
      phoneNumber: matches(
        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
        "Invalid Phone Number"
      ),
      firstName: isNotEmpty(),
      lastName: isNotEmpty(),
    }
  }

  static isBeingSold(home?: IStrapiEntity<IHome>) {
    if (!home) return false

    return (
      home?.attributes.sellStatus === "simple" ||
      home?.attributes.sellStatus === "cash"
    )
  }
}
