import {Text, Stack, Paper, Title, Group, Progress, Divider, Anchor} from "@mantine/core"
import {IStrapiEntity} from "services/strapi"
import {IHome} from "features/homes/types"
import {ITask, TaskContent} from "features/tasks"
import {ITaskStep} from "features/task-steps/types"
import {ReactComponent as FestivitiesSVG} from "assets/undraw/undraw_festivities.svg"
import {ClosingStepsTimeline} from "features/task-steps/components/task-steps.closing-steps-timeline";
import {taskStepHooks} from "features/task-steps/hooks";
import {useState} from "react";

interface Props {
    home?: IStrapiEntity<IHome>
    tasks?: IStrapiEntity<ITask>[]
    loading?: boolean
    currentTask?: IStrapiEntity<ITask> | null
    currentStep?: IStrapiEntity<ITaskStep> | null
}

export function TaskStepsCurrentStep(
    {
        home,
        tasks,
        loading,
        currentTask,
        currentStep,
    }: Props) {

    const {data: taskSteps} = taskStepHooks.useGet(
        {
            filters: {
                category: "sell",
                subCategory: home?.attributes?.sellStatus,
            },
        },
        home?.attributes?.sellStatus ? true : false
    )
    const [showAllCompletedTasks, setShowAllCompletedTasks] = useState(false)

    function getTasksForStep() {
        if (!tasks) {
            return []
        }
        return tasks?.filter(
            (task) =>
                task.attributes.stepId === currentStep?.attributes.stepId)
            ?.sort(
                (a, b) =>
                    (a.attributes.sequence ?? 0) - (b.attributes.sequence ?? 0)
            )
    }

    function getOrderedCompletedTasks(slice?: number) {
        if (!tasks) {
            return []
        }
        if (slice) {
            const filteredTasks = tasks.filter(
                (task) =>
                    task.attributes.stepId === currentStep?.attributes.stepId && task.attributes.completedOn)
                .sort(
                    (a, b) =>
                        (a.attributes.sequence ?? 0) - (b.attributes.sequence ?? 0))
            if (filteredTasks.length >= slice) {
                return filteredTasks.slice(filteredTasks.length - (slice), filteredTasks.length)
            } else {
                return filteredTasks
            }

        }
        return tasks?.filter(
            (task) =>
                task.attributes.stepId === currentStep?.attributes.stepId && task.attributes.completedOn)
            .sort(
                (a, b) =>
                    (a.attributes.sequence ?? 0) - (b.attributes.sequence ?? 0)
            )
    }

    function getOrderedIncompleteTasks() {
        if (!tasks) {
            return []
        }
        return tasks?.filter(
            (task) =>
                task.attributes.stepId === currentStep?.attributes.stepId && !task.attributes.completedOn)
            ?.sort(
                (a, b) =>
                    (a.attributes.sequence ?? 0) - (b.attributes.sequence ?? 0)
            )
    }

    function renderContent() {
        if (!loading && tasks?.every((t) => t.attributes.completedOn))
            return (
                <Paper>
                    <Stack spacing={0} py={24} align="center">
                        <FestivitiesSVG width={600} height={200}/>
                        <Stack
                            spacing={0}
                            mt={18}
                            style={{
                                maxWidth: 500,
                                textAlign: "center",
                            }}
                        >
                            <Text
                                size={24}
                                gradient={{
                                    from: "red",
                                    to: "orange",
                                    deg: 45,
                                }}
                                weight={600}
                                variant="gradient"
                            >
                                Nice work! All tasks complete.
                            </Text>
                            <Text size={18} mt={6} color="dimmed">
                                Your concierge should stay in touch with you and alert you if any
                                new tasks come up.
                            </Text>
                        </Stack>
                    </Stack>
                </Paper>
            )

        return (
            <Paper withBorder>
                <Stack spacing="lg">
                    <Stack spacing="xs" px={24} pt={16}>
                        <Group position="apart">
                            <Text size="lg" weight={600}>
                                Step: {currentStep?.attributes?.title}
                            </Text>
                            <Group>
                                {tasks && <Progress w={150} color="green"
                                                    value={Math.round((getOrderedCompletedTasks().length / getTasksForStep().length) * 100)}/>}
                                <Text size="sm" color="dimmed">
                                    {getOrderedCompletedTasks().length} of {getTasksForStep()?.length} Tasks Complete
                                </Text>
                            </Group>
                        </Group>
                        <Text color="dimmed" size="sm">{currentStep?.attributes?.description}</Text>
                    </Stack>
                    <Divider/>
                    <Stack px={24} pt={6} pb={24}>
                        {getOrderedCompletedTasks().length !== 0 &&
                            <Stack spacing={6}>
                                {showAllCompletedTasks ? getOrderedCompletedTasks().map((task, index) => (
                                        <TaskContent
                                            task={task}
                                            home={home}
                                            isCurrentTask={currentTask?.id === task.id}
                                        />
                                    )) :
                                    <Stack sx={theme => ({
                                        // [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
                                        //     height: 69,
                                        // },
                                        height: 50,
                                        position: "relative",
                                    })}>
                                        {getOrderedCompletedTasks(4).reverse()
                                            .map((task, index) => (
                                                <div key={index} style={{
                                                    position: 'absolute',
                                                    top: index * -3,
                                                    maxHeight: 80,
                                                    overflow: "hidden",
                                                    transform: "translate(-50%, 0)",
                                                    left: "50%",
                                                    zIndex: getOrderedCompletedTasks().length - index,
                                                    width: `${100 - (index * .5)}%`
                                                }}>
                                                    <TaskContent
                                                        index={index}
                                                        task={task}
                                                        home={home}
                                                        isCurrentTask={currentTask?.id === task.id}
                                                    />
                                                </div>
                                            ))}
                                    </Stack>
                                }
                                {getOrderedCompletedTasks().length > 1 &&
                                    <Anchor ml="auto" size="sm"
                                            onClick={() => setShowAllCompletedTasks(!showAllCompletedTasks)}>
                                        Show all completed tasks
                                    </Anchor>
                                }
                            </Stack>
                        }
                        <Stack>
                            {getOrderedIncompleteTasks()
                                .map((task, index) => (
                                    <TaskContent
                                        key={index}
                                        task={task}
                                        home={home}
                                        isCurrentTask={currentTask?.id === task.id}
                                    />
                                ))}
                        </Stack>
                    </Stack>
                </Stack>
            </Paper>
        )
    }

    return (
        <Stack>
            <Text weight={600} size="xl">Your Road To Closing</Text>
            <ClosingStepsTimeline
                home={home}
                tasks={tasks}
                taskSteps={taskSteps?.data}
            />
            {renderContent()}
        </Stack>
    )
}
