import { useNavigate, useParams } from "react-router-dom"
import { homeHooks } from "features/homes"
import { Content } from "components/common"
import { Button, Center, Group, Loader } from "@mantine/core"
import { IconArrowLeft } from "@tabler/icons"
import { useUserRoutes } from "routes"
import { useMemo } from "react"
import { taskHooks } from "features/tasks"

export function HomeSignTaskDocumentPage() {
  const { homeId, taskId } = useParams()
  const navigate = useNavigate()

  const { routesResolver } = useUserRoutes()

  const { isLoading: isLoadingHome, data: home } = homeHooks.useGetById(
    parseInt(homeId || ""),
    {
      populate: "*",
    }
  )

  const currentTask = useMemo(() => {
    if (isLoadingHome || !home?.data?.attributes?.tasks?.data) return null

    return home.data.attributes.tasks.data.find(
      (t) => !t.attributes.completedOn && t.id === parseInt(taskId || "")
    )
  }, [home])

  const { isLoading: isLoadingDocument, data: embeddedDocumentUrl } =
    taskHooks.useGetEmbeddedDocumentUrl(currentTask?.id)

  if (isLoadingHome || isLoadingDocument)
    return (
      <Center h={"100%"} w={"100%"}>
        <Loader />
      </Center>
    )

  // Redirect back home if task is already completed or task doesn't exist
  if (
    !isLoadingHome &&
    (!currentTask || currentTask.id !== parseInt(taskId || ""))
  ) {
    navigate(routesResolver.getHomeDetailsById(homeId || ""))
  }

  return (
    <>
      <Content.Header>
        <Content.Title>
          <Group>
            <Button
              leftIcon={<IconArrowLeft />}
              onClick={() =>
                navigate(routesResolver.getHomeDetailsById(homeId || ""))
              }
            >
              Back
            </Button>
            {currentTask?.attributes.title}
          </Group>
        </Content.Title>
      </Content.Header>
      <Content.Body mt={12} h={"100vh"}>
        <Content.Section p={16} h={"100vh"}>
          <iframe src={embeddedDocumentUrl} height={"100%"} width={"100%"} />
        </Content.Section>
      </Content.Body>
    </>
  )
}
