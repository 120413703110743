import {
  IStrapiEntity,
  IStrapiFile,
  IStrapiRelationship,
  StrapiBaseFields,
} from "services/strapi"
import { IHome } from "features/homes"

export interface IOffer extends StrapiBaseFields {
  home?: IStrapiRelationship<IStrapiEntity<IHome>[]>
  amount?: number
  status: EOfferStatus
  rejectionDetails: string
  counterDetails: string
  expiresOn?: Date
  counteredOn?: Date
  rejectedOn?: Date
  acceptedOn?: Date
  offererName: string
  offerDocument: IStrapiRelationship<IStrapiEntity<IStrapiFile>>
}

export interface IOfferFormValues extends Partial<Omit<IOffer, "home">> {
  id?: number
}

export enum EOfferStatus {
  PENDING = "pending",
  COUNTERED = "countered",
  COUNTER_SIGNED = "counter-signed",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
  EXPIRED = "expired",
}
