import { HomeUtils, ICoOwner } from "features/homes"
import { Button, Grid, Group, TextInput, Text } from "@mantine/core"
import { Plus } from "tabler-icons-react"
import { ListItemsForm } from "components/common"
import { UseFormReturnType } from "@mantine/form"

interface Props {
  form: UseFormReturnType<{ items: ICoOwner[] }>
}

export function HomeCoOwnersListForm({ form }: Props) {
  return (
    <ListItemsForm<ICoOwner>
      form={form}
      renderFormItem={(item, index) => (
        <Grid w="100%">
          <Grid.Col sm={6}>
            <Grid.Col sm={6}>
              <Text size="sm">First Name</Text>
            </Grid.Col>
            <TextInput
              placeholder="Co-owner's first name"
              {...form.getInputProps(`items.${index}.firstName`)}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <Grid.Col sm={6}>
              <Text size="sm">Last Name</Text>
            </Grid.Col>
            <TextInput
              placeholder="Co-owner's last name"
              {...form.getInputProps(`items.${index}.lastName`)}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <Grid.Col sm={6}>
              <Text size="sm">Email Address</Text>
            </Grid.Col>
            <TextInput
              placeholder="Co-owner's email address"
              {...form.getInputProps(`items.${index}.emailAddress`)}
            />
          </Grid.Col>
          <Grid.Col sm={6}>
            <Grid.Col sm={6}>
              <Text size="sm">Phone Number</Text>
            </Grid.Col>
            <TextInput
              placeholder="Co-owner's phone number"
              {...form.getInputProps(`items.${index}.phoneNumber`)}
            />
          </Grid.Col>
        </Grid>
      )}
      renderButton={() => (
        <Group mt={12}>
          <Button
            variant="subtle"
            leftIcon={<Plus size={18} />}
            onClick={() =>
              form.insertListItem(
                "items",
                HomeUtils.transformCoOwnerInitialValues()
              )
            }
          >
            Add Co-owner Row
          </Button>
        </Group>
      )}
    />
  )
}
