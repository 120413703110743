import { strapi } from "lib/strapi-api"
import { IStrapiEntity, IStrapiResponse, ModelRoutes } from "services/strapi"
import qs from "qs"
import axios, { AxiosError } from "axios"
import { showNotification } from "@mantine/notifications"
import { StringUtils } from "utils"

enum Method {
  Created = "created",
  Updated = "updated",
  Deleted = "deleted",
  Fetched = "fetched",
}

export class ModelApiService<ModelType> {
  route
  disableErrorNotifications
  disableSuccessNotifications
  imageFields

  constructor(
    route: ModelRoutes,
    imageFields: string[] = [],
    disableSuccessNotifications = true,
    disableErrorNotifications = false
  ) {
    this.route = route
    this.disableErrorNotifications = disableErrorNotifications
    this.disableSuccessNotifications = disableSuccessNotifications
    this.imageFields = imageFields
  }

  showSuccessNotification(method: Method) {
    if (!this.disableSuccessNotifications) {
      showNotification({
        title: `Success in ${StringUtils.capitalizeFirstLetter(this.route)}`,
        message: `Your entity for ${this.route} was successfully ${method}!`,
        color: "green",
        autoClose: 1000 * 10,
      })
    }
  }

  showErrorNotification(method: Method, error: AxiosError) {
    if (!this.disableErrorNotifications) {
      showNotification({
        title: `Error in ${StringUtils.capitalizeFirstLetter(this.route)}`,
        message: `There was an error, and your entity in ${this.route} was not ${method}. If this problem, persists, please contact us at contact@behoused.com with the following error:\n ${error.response?.data?.error?.message}`,
        color: "red",
        autoClose: 1000 * 10,
      })
    }
  }

  serializeToStrapiFormData(values: any) {
    const formData = new FormData()

    const curData: Record<string, any> = {}
    Object.keys(values).forEach((key) => {
      if (this.imageFields.indexOf(key) === -1) {
        curData[key] = values[key]
      }
    })

    formData.append("data", JSON.stringify(curData))

    this.imageFields.forEach((field) => {
      const curValue = values[field]
      if (curValue && curValue.length === 1) {
        formData.append(`files.${field}`, curValue[0], curValue[0].name)
      }
    })
    return formData
  }

  async create<ValuesType>(
    values: ValuesType
  ): Promise<IStrapiResponse<IStrapiEntity<ModelType>> | undefined> {
    try {
      const isFormData = values instanceof FormData
      const res = await strapi.post(
        `/${this.route}`,
        isFormData ? values : { data: values }
      )
      this.showSuccessNotification(Method.Created)
      return res.data as IStrapiResponse<IStrapiEntity<ModelType>>
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(`[Create] Error in route: ${this.route}`, e.message)
        this.showErrorNotification(Method.Created, e)
      }
      return undefined
    }
  }

  async update<ValuesType>(
    id: number,
    values: ValuesType,
    rawQuery: any
  ): Promise<IStrapiResponse<IStrapiEntity<ModelType>> | undefined> {
    const query = qs.stringify(rawQuery)
    try {
      const isFormData = values instanceof FormData
      const res = await strapi.put(
        `/${this.route}/${id}?${query}`,
        isFormData ? values : { data: values }
      )
      this.showSuccessNotification(Method.Updated)
      return res.data as IStrapiResponse<IStrapiEntity<ModelType>>
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(`[Update] Error in route: ${this.route}`, e.message)
        this.showErrorNotification(Method.Updated, e)
      }
      return undefined
    }
  }

  async findMany<QueryType>(
    rawQuery: QueryType
  ): Promise<IStrapiResponse<IStrapiEntity<ModelType>[]> | undefined> {
    const query = qs.stringify(rawQuery)
    try {
      const res = await strapi.get(`/${this.route}?${query}`)
      return res.data as IStrapiResponse<IStrapiEntity<ModelType>[]>
    } catch (e) {
      if (axios.isAxiosError(e)) {
        this.showErrorNotification(Method.Fetched, e)
        console.error(`[FindMany] Error in route: ${this.route}`, e.message)
      }
      return undefined
    }
  }

  async findOne<QueryType>(
    id: number,
    rawQuery: QueryType
  ): Promise<IStrapiResponse<IStrapiEntity<ModelType>> | undefined> {
    if (!id){
      return undefined
    }
    const query = qs.stringify(rawQuery)
    try {
      const res = await strapi.get(`/${this.route}/${id}?${query}`)
      return res.data as IStrapiResponse<IStrapiEntity<ModelType>>
    } catch (e) {
      if (axios.isAxiosError(e)) {
        this.showErrorNotification(Method.Fetched, e)
        console.error(`[Find] Error in route: ${this.route}`, e.message)
      }
      return undefined
    }
  }

  async findOneBySlug(
    slug: string,
    rawQuery: any
  ): Promise<IStrapiEntity<ModelType> | undefined> {
    if (rawQuery) {
      if (rawQuery.filters) {
        rawQuery.filters = {
          ...rawQuery.filters,
          slug: slug,
        }
      } else {
        rawQuery.filters = { slug: slug }
      }
    }
    const query = qs.stringify(rawQuery)
    try {
      const res = await strapi.get(`/${this.route}?${query}`)
      const entities = res.data as IStrapiResponse<IStrapiEntity<ModelType>[]>
      if (entities.data && entities.data.length === 1) {
        return entities.data[0]
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        this.showErrorNotification(Method.Fetched, e)
        console.error(`[FindBySlug] Error in route: ${this.route}`, e.message)
      }
      return undefined
    }
  }

  async delete(
    id: number
  ): Promise<IStrapiResponse<IStrapiEntity<ModelType>> | undefined> {
    try {
      const res = await strapi.delete(`/${this.route}/${id}`)
      this.showSuccessNotification(Method.Deleted)
      return res.data as IStrapiResponse<IStrapiEntity<ModelType>>
    } catch (e) {
      if (axios.isAxiosError(e)) {
        this.showErrorNotification(Method.Deleted, e)
        console.error(`[Delete] Error in route: ${this.route}`, e.message)
      }
      return undefined
    }
  }
}
