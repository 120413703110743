import { NoData } from "components/common"
import { DefaultPageWrapper } from "layouts"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
  Button,
  Center,
  Group,
  Loader,
  Stack,
  Text,
  Title,
} from "@mantine/core"
import { useEffect } from "react"
import { taskHooks } from "features/tasks"
import { ReactComponent as FestivitiesSVG } from "assets/undraw/undraw_festivities.svg"
import { useUserRoutes } from "routes"

export function HomeListingPaymentConfirmation() {
  const { homeId } = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get("taskId")
  const { routesResolver } = useUserRoutes()

  const { data: task, isLoading: isLoadingTask } = taskHooks.useGetById(
    parseInt(taskId || ""),
    {}
  )

  const { mutateAsync: updateTask, isLoading: updatingTask } =
    taskHooks.useUpdate()

  async function markTaskAsDone(taskId: number) {
    await updateTask({ id: taskId, values: { completedOn: new Date() } })
  }

  // Automatically mark related signing task as complete
  useEffect(() => {
    try {
      // Remove stripe secrets from url because it appends them for some reason on callback
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.delete("payment_intent_client_secret")
      searchParams.delete("payment_intent")
      searchParams.delete("redirect_status")
      const newUrl = `${window.location.pathname}?${searchParams.toString()}`
      window.history.replaceState({}, "", newUrl)
    } catch (error) {
      console.log("error removing stripe data from url", error)
    }

    if (task && task?.data && !task?.data.attributes.completedOn) {
      markTaskAsDone(task.data.id)
    }
  }, [task, isLoadingTask])

  const renderContent = () => {
    if (isLoadingTask || updatingTask) {
      return (
        <Center h={"100%"} w={"100%"}>
          <Group noWrap>
            <Loader size="sm" />
            <Text>Finishing up payment, please do not leave the page...</Text>
          </Group>
        </Center>
      )
    }

    if (!task) {
      return (
        <Center h={"100%"} w={"100%"}>
          <NoData title="Task not found" />
        </Center>
      )
    }

    return (
      <Center h={"100%"} w={"100%"}>
        <Stack spacing={0} py={24} align="center">
          <FestivitiesSVG width={600} height={200} />
          <Stack
            spacing={0}
            mt={18}
            style={{
              maxWidth: 600,
              textAlign: "center",
            }}
          >
            <Text
              size={24}
              gradient={{
                from: "red",
                to: "orange",
                deg: 45,
              }}
              weight={600}
              variant="gradient"
            >
              Thank you for your payment! Your home listing setup is now in
              motion. Here's what happens next:
            </Text>
            <Stack spacing={"lg"} mt={"lg"} mb={"lg"}>
              <Stack spacing={0}>
                <Title size={"lg"}>Professional Home Photos</Title>
                <Text color="dimmed" size="md" span>
                  Our team will contact you shortly to schedule a convenient
                  time for the photo session.
                </Text>
              </Stack>
              <Stack spacing={0}>
                <Title size={"lg"}>Custom For Sale Sign</Title>
                <Text color="dimmed" size="md" span>
                  Your personalized for sale sign is being created and will be
                  delivered to your home soon.
                </Text>
              </Stack>
              <Stack spacing={0}>
                <Title size={"lg"}>Secure Key Lockbox</Title>
                <Text color="dimmed" size="md" span>
                  We will also arrange the delivery of your key lockbox to
                  ensure your home is ready for showings.
                </Text>
              </Stack>
            </Stack>
            <Text size={18} mt={6} color="dimmed" mb={"lg"}>
              Your concierge will be notified and will reach out to you shortly.
              You may now leave the page.
            </Text>
            <Button
              onClick={() => navigate(routesResolver.getHomeById(homeId || ""))}
            >
              Return to Dashboard
            </Button>
          </Stack>
        </Stack>
      </Center>
    )
  }

  return <DefaultPageWrapper>{renderContent()}</DefaultPageWrapper>
}
