import {ModelHooks} from "services/strapi";
import { taskStepApi } from "../services/task-step.strapi.api";
import { ITaskStep } from "../types/task-step.types";

class TaskStepHooks extends ModelHooks<ITaskStep> {
    /** Add more functions or properties here that are not described in the Base Strapi
     *  Model Hooks Service.
     **/

}

export const taskStepHooks = new TaskStepHooks(taskStepApi);
