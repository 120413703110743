import { useNavigate, useParams } from "react-router-dom"
import { Content } from "components/common"
import { Button, Center, Group, Loader, Text } from "@mantine/core"
import { IconArrowLeft } from "@tabler/icons"
import { useUserRoutes } from "routes"
import { EOfferStatus, offerHooks } from "features/offers"
import { ETaskFormTypes } from "features/tasks"

export function HomeSignCounterOfferDocumentPage() {
  const { homeId, offerId, taskId } = useParams()
  const navigate = useNavigate()

  const { routesResolver } = useUserRoutes()

  const { isLoading: isLoadingOffer, data: offer } = offerHooks.useGetById(
    parseInt(offerId || ""),
    {
      populate: "*",
    }
  )

  const { isLoading: isLoadingDocument, data: embeddedDocumentUrl } =
    offerHooks.useGetEmbeddedDocumentUrl(parseInt(offerId || ""))

  if (isLoadingOffer || isLoadingDocument)
    return (
      <Center h={"100%"} w={"100%"}>
        <Loader />
      </Center>
    )

  // Redirect back home if offer is not in countered status
  if (
    !isLoadingOffer &&
    (!offer || offer.data.attributes.status !== EOfferStatus.COUNTERED)
  ) {
    navigate(routesResolver.getHomeDetailsById(homeId || ""))
  }

  const renderContent = () => {
    if (!isLoadingOffer && !embeddedDocumentUrl)
      return (
        <Center h={"100%"} w={"100%"}>
          <Text>
            Your counter offer document is not generated yet, please check back
            later or your concierge will get in touch with you
          </Text>
        </Center>
      )

    return (
      <Content.Body mt={12} h={"100vh"}>
        <Content.Section p={16} h={"100vh"}>
          <iframe src={embeddedDocumentUrl} height={"100%"} width={"100%"} />
        </Content.Section>
      </Content.Body>
    )
  }

  return (
    <>
      <Content.Header>
        <Content.Title>
          <Group>
            <Button
              leftIcon={<IconArrowLeft />}
              onClick={() =>
                navigate(
                  routesResolver.getInteractiveFormByHomeId(
                    homeId || "",
                    ETaskFormTypes.REVIEW_OFFERS,
                    parseInt(taskId || "")
                  )
                )
              }
            >
              Back
            </Button>
            Counter Offer
          </Group>
        </Content.Title>
      </Content.Header>
      {renderContent()}
    </>
  )
}
