import {
  Text,
  Stack,
  Group,
  useMantineTheme,
  Divider,
  ActionIcon,
} from "@mantine/core"
import { Dropzone, FileWithPath, MIME_TYPES } from "@mantine/dropzone"
import { IconPhoto, IconUpload, IconX } from "@tabler/icons"
import React, { useState } from "react"
import { FILE_INPUT_DEFAULT_TEXT } from "settings"
import { CircleMinus, FileUpload } from "tabler-icons-react"

interface Props {
  onChange: (value: FileWithPath[]) => void
  onRemove: (index: number) => void
  value: FileWithPath[]
  height?: number
}

export function FileDropzone({ onChange, onRemove, value, height }: Props) {
  const [onRemoveButtonHover, setOnRemoveButtonHover] = useState(false) // Used to fine-tune control forfile selector open by mouse click
  const theme = useMantineTheme()

  const previews = value.map((f, i) => {
    return (
      <React.Fragment key={i}>
        <Group position="apart">
          <Group spacing={5}>
            <FileUpload color={theme.colors.green[4]} />
            <Text size={"sm"}>{f.name}</Text>
          </Group>
          <ActionIcon
            size="sm"
            onClick={(event) => {
              event.preventDefault()
              onRemove(i)
            }}
            onMouseEnter={() => setOnRemoveButtonHover(true)}
            onMouseLeave={() => setOnRemoveButtonHover(false)}
          >
            <CircleMinus
              size={18}
              strokeWidth={1.5}
              color={theme.colors.red[6]}
            />
          </ActionIcon>
        </Group>
        {i !== value.length - 1 && <Divider />}
      </React.Fragment>
    )
  })

  return (
    <Dropzone
      onDrop={onChange}
      multiple={true}
      maxSize={200000000}
      maxFiles={15}
      activateOnClick={!onRemoveButtonHover || value.length === 0} // Without this, file selector window will pop-up when you click to remove a file
      accept={[
        MIME_TYPES.png,
        MIME_TYPES.gif,
        MIME_TYPES.jpeg,
        MIME_TYPES.svg,
        MIME_TYPES.webp,
        MIME_TYPES.avif,
        MIME_TYPES.mp4,
        MIME_TYPES.zip,
        MIME_TYPES.csv,
        MIME_TYPES.pdf,
        MIME_TYPES.doc,
        MIME_TYPES.docx,
        MIME_TYPES.xls,
        MIME_TYPES.xlsx,
        MIME_TYPES.ppt,
        MIME_TYPES.pptx,
      ]}
      styles={{ inner: { pointerEvents: "all" } }}
    >
      <Group
        position="center"
        style={{ height: height ? height : 150, pointerEvents: "none" }}
      >
        <Dropzone.Accept>
          <IconUpload
            size={50}
            stroke={1.5}
            color={
              theme.colors[theme.primaryColor][
                theme.colorScheme === "dark" ? 4 : 6
              ]
            }
          />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX
            size={50}
            stroke={1.5}
            color={theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]}
          />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconPhoto size={50} stroke={1.5} />
        </Dropzone.Idle>

        <Stack spacing={0}>
          <Text size="xl" inline>
            Drag files here or click to select files
          </Text>
          <Text size="sm" color="dimmed" inline mt={7}>
            {FILE_INPUT_DEFAULT_TEXT}
          </Text>
        </Stack>
      </Group>
      {value?.length > 0 ? <Stack>{previews}</Stack> : null}
    </Dropzone>
  )
}
