import { ITask } from "features/tasks"

import { Button, Text } from "@mantine/core"
import { IStrapiEntity } from "services/strapi"
import { IHome } from "features/homes"
import { IconUpload } from "@tabler/icons"
import { useModals } from "@mantine/modals"
import { DocumentsFileUploadForm } from "features/documents"

interface Props {
  home?: IStrapiEntity<IHome>
  task?: IStrapiEntity<ITask>
  onTaskComplete?: () => void
}

export function TaskUploadDocumentButton({
  home,
  task,
  onTaskComplete,
}: Props) {
  const modals = useModals()

  if (!task) return null

  function onClick() {
    if (!home) return

    modals.openModal({
      withCloseButton: true,
      closeOnClickOutside: true,
      closeOnEscape: true,
      centered: true,
      size: "900",
      overflow: "outside",
      title: <Text size={"xl"}>Upload Document</Text>,
      children: (
        <DocumentsFileUploadForm
          homeId={home.id}
          folderId={"miscellanious"} //TODO: change this to the correct folderId based on task property
          onUpload={onUpload}
        />
      ),
    })
  }

  async function onUpload() {
    modals.closeAll()
    if (onTaskComplete) onTaskComplete()
  }

  return (
    <Button leftIcon={<IconUpload />} onClick={onClick}>
      Upload Document
    </Button>
  )
}
