import {
  taskHooks,
  ITask,
  ETaskType,
  TaskCalendlyButton,
  TaskSignDocumentButton,
  TaskUploadDocumentButton,
  TaskLinkFormButton,
} from "features/tasks"

import {
  Badge,
  Box,
  Button,
  Center,
  Group,
  Paper,
  Stack,
  Text,
  Tooltip,
  useMantineTheme,
} from "@mantine/core"
import { IconCircle, IconCircleCheck, IconClock } from "@tabler/icons"
import { IStrapiEntity } from "services/strapi"
import { IHome } from "features/homes"

interface Props {
  home?: IStrapiEntity<IHome>
  task?: IStrapiEntity<ITask>
  isCurrentTask?: boolean
  index?: number
}

export function TaskContent({ home, task, isCurrentTask, index = 0 }: Props) {
  const theme = useMantineTheme()
  const { mutateAsync: updateTask, isLoading: updatingTask } =
    taskHooks.useUpdate()

  async function markTaskAsDone(taskId: number) {
    await updateTask({ id: taskId, values: { completedOn: new Date() } })
  }

  async function markTaskAsIncomplete(taskId: number) {
    await updateTask({ id: taskId, values: { completedOn: null } })
  }

  if (!task) return null

  const isCompleted = () => {
    return task?.attributes?.completedOn
  }

  const renderHeader = () => {
    return (
      <Group w="100%" spacing={0} position="apart">
        <Group spacing={6} noWrap>
          {renderIcon()}
          <Text
            fw={600}
            lineClamp={1}
            size="sm"
            span
            color={theme.colors[getColorFromStatus()][8]}
          >
            {task.attributes.title}
          </Text>
        </Group>
        <Group
          spacing={6}
          noWrap
          sx={(theme) => ({
            [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
              display: isCompleted() ? "none" : "flex",
            },
          })}
        >
          {/* <Text size="sm" color={theme.colors[getColorFromStatus()][8]}>Type:</Text> */}
          <Text size="sm" color={theme.colors[getColorFromStatus()][8]}>
            {/* {task.attributes.type === ETaskType.SCHEDULING &&
                            "Schedule a Meeting"}
                        {task.attributes.type === ETaskType.SIGNING && "Sign Document"}
                        {task.attributes.type === ETaskType.UPLOAD && "Upload Document"} */}
            {task.attributes.type === ETaskType.HOLD && "No Action Required"}
            {/* {task.attributes.type === ETaskType.INTERACTIVE && "Submit Form"} */}
          </Text>
        </Group>
      </Group>
    )
  }

  const renderDevButton = () => {
    if (!isCurrentTask) return null

    return (
      <>
        {!isCompleted() && (
          <Button
            onClick={() => markTaskAsDone(task.id)}
            loading={updatingTask}
            color="orange"
          >
            Complete Task
          </Button>
        )}
        {isCompleted() && (
          <Button
            onClick={() => markTaskAsIncomplete(task.id)}
            loading={updatingTask}
            color="orange"
          >
            Set to Incomplete
          </Button>
        )}
      </>
    )
  }

  const renderIcon = () => {
    let toolTipText = isCompleted()
      ? "Task completed"
      : "Task not yet started - you do not have to do this yet"
    if (isCurrentTask) toolTipText = "Current task"

    const iconColor = theme.colors[getColorFromStatus()][6]

    let Icon = isCompleted() ? IconCircleCheck : IconCircle
    if (isCurrentTask) Icon = IconClock

    return (
      <Tooltip label={toolTipText} position={"bottom"}>
        <Center p={0} m={0}>
          <Icon size={18} color={iconColor} />
        </Center>
      </Tooltip>
    )
  }

  const renderControls = () => {
    if (isCurrentTask)
      return (
        <Group position="apart" noWrap>
          {task?.attributes.type === ETaskType.SCHEDULING && (
            <TaskCalendlyButton
              home={home}
              task={task}
              onTaskComplete={() => markTaskAsDone(task.id)}
            />
          )}
          {task?.attributes.type === ETaskType.SIGNING && (
            <TaskSignDocumentButton home={home} task={task} />
          )}
          {task?.attributes.type === ETaskType.UPLOAD && (
            <TaskUploadDocumentButton
              home={home}
              task={task}
              onTaskComplete={() => markTaskAsDone(task.id)}
            />
          )}
          {task?.attributes.type === ETaskType.INTERACTIVE && (
            <TaskLinkFormButton
              home={home}
              task={task}
              onTaskComplete={() => markTaskAsDone(task.id)}
            />
          )}
        </Group>
      )

    return null
  }

  function getColorFromStatus() {
    if (isCompleted()) {
      return "green"
    } else if (isCurrentTask) {
      return "blue"
    } else {
      return "gray"
    }
  }

  const renderStatusBadge = () => {
    let badgeText = isCompleted() ? "Completed" : "Pending"
    if (isCurrentTask) badgeText = "In Progress"

    let badgeColor = isCompleted() ? "green" : "gray"
    if (isCurrentTask) badgeColor = "blue"

    return <Badge color={badgeColor}>{badgeText}</Badge>
  }

  return (
    <Paper
      py="sm"
      px="md"
      withBorder
      sx={{
        borderColor: theme.colors[getColorFromStatus()][6 - index],
        background: theme.colors[getColorFromStatus()][0],
      }}
    >
      <Stack spacing={"xl"}>
        <Stack spacing={6}>
          <Group noWrap>{renderHeader()}</Group>
          {!isCompleted() && (
            <Text size={"sm"} color={isCurrentTask ? "inherit" : "gray"}>
              {task.attributes.text}
            </Text>
          )}
        </Stack>
        {!isCompleted() && (
          <Group position="apart">
            {renderControls()}
            {renderDevButton()}
          </Group>
        )}
      </Stack>
    </Paper>
  )
}
