import {useState} from 'react';
import {Box, Button, Grid, Group, Textarea, TextInput} from '@mantine/core';
import {useForm} from '@mantine/form';
import {ArrowRight} from "tabler-icons-react";

interface Props {
    onSubmitQuery?: (query: any) => void,
    initialValues?: any
}

export function HomeSendMessageForm({onSubmitQuery, initialValues}: Props) {
    const [creatingHome, setCreatingHome] = useState(false);
    const form = useForm<{ message:string }>({
        initialValues: initialValues || {},
        validate: {},
    });


    async function onSubmit(values: typeof form.values) {
        if (onSubmitQuery) {
            setCreatingHome(true);
            await onSubmitQuery(values)
            setCreatingHome(false);
        }
    }


    return (
        <Box mx="auto">
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Grid>
                    <Grid.Col>
                        <Textarea label="Your Message" {...form.getInputProps('message')}/>
                    </Grid.Col>
                    <Grid.Col sm={12}>
                        <Group position="right" mt="md">
                            <Button type="submit" loading={ creatingHome }
                                    rightIcon={<ArrowRight/>}>
                                Send Message
                            </Button>
                        </Group>
                    </Grid.Col>
                </Grid>
            </form>
        </Box>
    )
}
