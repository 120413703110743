import { UseFormReturnType } from "@mantine/form"
import React, { Fragment, useState } from "react"

import {
  ActionIcon,
  Button,
  Divider,
  Group,
  Pagination,
  PaginationProps,
  Skeleton,
  Stack,
  Table,
  Text,
  useMantineTheme,
} from "@mantine/core"

import { NoData } from "components/common/no-data/no-data"
import { ArrowRight, CircleMinus, CirclePlus, X } from "tabler-icons-react"
import { PermissionsReturn } from "hooks"

export interface ListItemsTableSettings<TData, TFormValues> {
  key: keyof TData | string
  label: string | React.ReactNode
  required?: boolean
  isNotFormValue?: boolean
  permissions?: PermissionsReturn
  Render?: {
    Static?: React.ComponentType<{
      data: TData
      permissions?: PermissionsReturn
    }>
    Form?: ({
      form,
      disabled,
      id,
      permissions,
    }: {
      form: UseFormReturnType<{ items: TFormValues[] }>
      disabled?: boolean
      id: number
      permissions?: PermissionsReturn
    }) => React.ReactNode
  }
}

export type ListItemsTableProps<TData, TFormValues> = {
  settings: ListItemsTableSettings<TData, TFormValues>[]
  data?: TData[]
  form?: UseFormReturnType<{ items: TFormValues[] }>
  asForm?: boolean
  loading?: boolean
  overrideFields?: Record<
    keyof TData | string,
    Partial<ListItemsTableSettings<TData, TFormValues>>
  >
  addFields?: ListItemsTableSettings<TData, TFormValues>[]
  disableFields?: (keyof TData | string)[]
  renderButton?: () => React.ReactNode
  renderAddButton?: () => React.ReactNode
  renderNoData?: () => React.ReactNode
  numLoadingRows?: number
  pagination?: PaginationProps
  disableHeaders?: boolean
  requiresFirstItem?: boolean
  permissions?: PermissionsReturn
  tableKey?: string
}

export function ListItemsTable<TData, TFormValues>({
  settings: incomingSettings,
  data,
  asForm,
  loading,
  numLoadingRows = 5,
  overrideFields,
  disableFields,
  renderButton,
  renderAddButton,
  form,
  pagination,
  renderNoData,
  addFields,
  disableHeaders,
  requiresFirstItem,
  permissions,
  tableKey,
}: ListItemsTableProps<TData, TFormValues>) {
  const settings = getMergedSettings(incomingSettings)

  function getMergedSettings(
    incoming: ListItemsTableSettings<TData, TFormValues>[]
  ) {
    const curSettings: ListItemsTableSettings<TData, TFormValues>[] = []
    for (let i = 0; i < incoming.length; i++) {
      const setting = incoming[i]
      if (disableFields) {
        if (disableFields.indexOf(setting.key) === -1) {
          if (overrideFields && overrideFields[setting.key] !== undefined) {
            const overridenSetting = {
              ...setting,
              ...overrideFields[setting.key],
            }
            curSettings.push(overridenSetting)
          } else {
            curSettings.push(setting)
          }
        }
      } else {
        if (overrideFields && overrideFields[setting.key] !== undefined) {
          const overridenSetting = {
            ...setting,
            ...overrideFields[setting.key],
          }
          curSettings.push(overridenSetting)
        } else {
          curSettings.push(setting)
        }
      }
    }
    if (addFields) {
      for (let i = 0; i < addFields.length; i++) {
        const addField = addFields[i]
        curSettings.push(addField)
      }
    }
    return curSettings
  }

  function renderHeaders() {
    const ret = settings.map((setting) => {
      return <th key={setting.key.toString()}>{setting.label}</th>
    })
    return ret
  }

  function renderStaticBodyRows() {
    if (loading) {
      const rows = []
      for (let i = 0; i < numLoadingRows; i++) {
        rows.push(
          <tr key={i}>
            {settings.map((setting) => (
              <td key={setting.key.toString()}>
                <Skeleton height={20} />
              </td>
            ))}
          </tr>
        )
      }
      return rows
    } else if (data) {
      return data.map((datum, i) => (
        <tr key={`datum-${i}`}>
          {settings.map((setting) => {
            const { key, Render } = setting
            if (Render?.Static) {
              return (
                <td
                  key={key.toString()}
                  id={`${tableKey}__${(datum as any)?.id}`}
                >
                  <Render.Static permissions={permissions} data={datum} />
                </td>
              )
            } else {
              return <td key={key.toString()} />
            }
          })}
          {asForm && form && form.values.items.length !== 0 && <td></td>}
        </tr>
      ))
    }
  }

  function renderFormBodyRows() {
    if (!asForm || !form) {
      return null
    }
    return (
      <Fragment>
        {form.values.items.map((item, i) => (
          <tr key={`item-${i}`}>
            {settings.map((setting) => {
              const { key, Render } = setting
              if (Render?.Form) {
                return (
                  <td key={key.toString()}>
                    {Render.Form({
                      form: form,
                      id: i,
                      permissions,
                    })}
                  </td>
                )
              } else {
                return <td key={key.toString()} />
              }
            })}

            <td style={{ width: "0.1%", whiteSpace: "nowrap" }}>
              {(i !== 0 || !requiresFirstItem) && (
                <ActionIcon
                  size="xs"
                  onClick={() => form.removeListItem(`items`, i)}
                >
                  <CircleMinus strokeWidth={1.5} color="red" />
                </ActionIcon>
              )}
            </td>
          </tr>
        ))}
        {renderAddButton && (
          <tr>
            <td
              style={{
                borderTop: 0,
                padding:
                  form.values.items.length !== 0
                    ? "0px 10px 12px 10px"
                    : "10px 12px",
              }}
              colSpan={5}
            >
              {renderAddButton()}
            </td>
          </tr>
        )}
        {renderButton && (
          <tr>
            <td
              style={{ borderTop: 0, padding: "0px 10px 12px 10px" }}
              colSpan={12}
            >
              <Group position="right">{renderButton()}</Group>
            </td>
          </tr>
        )}
      </Fragment>
    )
  }

  function _renderNoData() {
    if (data && data.length === 0 && !asForm) {
      if (renderNoData) {
        return renderNoData()
      } else {
        return <NoData title="No Data" />
      }
    } else {
      return null
    }
  }

  return (
    <Stack p={0} spacing={0}>
      <Table>
        {!disableHeaders && (
          <thead>
            <tr>
              {renderHeaders()}
              {asForm &&
                form?.values?.items &&
                form.values.items.length > 0 && <th></th>}
            </tr>
          </thead>
        )}
        <tbody>
          {renderStaticBodyRows()}
          {renderFormBodyRows()}
        </tbody>
      </Table>
      {_renderNoData()}
      {pagination && pagination.total > 1 && !loading && (
        <Fragment>
          <Divider variant="dotted" />
          <Pagination
            p={12}
            sx={{
              ".mantine-Pagination-item": {
                padding: 0,
              },
            }}
            size="xs"
            {...pagination}
          />
        </Fragment>
      )}
    </Stack>
  )
}

interface DefaultButtonsProps<TFormValues> {
  onClickCancel: () => void
  onClickSubmit?: () => Promise<void>
  form: UseFormReturnType<TFormValues>
  cancelButtonText?: string
  submitButtonText?: string
}

export function ListTableDefaultButtons<TFormValues>({
  onClickCancel,
  onClickSubmit,
  form,
  cancelButtonText,
  submitButtonText,
}: DefaultButtonsProps<TFormValues>) {
  const [isSubmitting, setIsSubmitting] = useState(false)

  async function _onSubmit() {
    setIsSubmitting(true)
    if (onClickSubmit) {
      await onClickSubmit()
    }
    setIsSubmitting(false)
  }

  return (
    <Group spacing={9}>
      <Button
        leftIcon={<X size={18} />}
        color="gray"
        variant="subtle"
        size="xs"
        onClick={onClickCancel}
      >
        {cancelButtonText ? cancelButtonText : "Cancel"}
      </Button>
      <Button
        disabled={!form.isDirty() || !form.isValid()}
        onClick={_onSubmit}
        loading={isSubmitting}
        type="submit"
        rightIcon={<ArrowRight size={18} />}
      >
        {submitButtonText ? submitButtonText : "Submit"}
      </Button>
    </Group>
  )
}

interface DefaultAddButtonProps<TFormValues> {
  buttonText: string
  form: UseFormReturnType<IListFormWrapper<TFormValues>>
  newItem: Partial<TFormValues>
}

export function ListTableDefaultAddButton<TFormValues>({
  form,
  buttonText,
  newItem,
}: DefaultAddButtonProps<TFormValues>) {
  const theme = useMantineTheme()
  return (
    <Button
      onClick={() => form.insertListItem("items", newItem)}
      variant="subtle"
      leftIcon={<CirclePlus size={18} />}
    >
      <Text weight={500} color={theme.colors.dark[3]}>
        {buttonText}
      </Text>
    </Button>
  )
}

export interface IListFormWrapper<TFormValues> {
  items: TFormValues[]
}
