import {IFlatUserDatum, IUserDatum} from "features/user-data";
import {IStrapiEntity} from "services/strapi";

export function getInitialsFromFullName(userDatum?: IStrapiEntity<IUserDatum> | IFlatUserDatum) {
    let fullName;
    if (userDatum) {
        if (Object.hasOwn(userDatum, 'attributes')) {
            fullName = (userDatum as IStrapiEntity<IUserDatum>).attributes.fullName;
        } else {
            fullName = (userDatum as IFlatUserDatum).fullName;
        }
    }
    if (fullName) {
        const split = fullName.split(" ");
        if (split.length > 1) {
            return `${split[0].charAt(0)}${split[1].charAt(0)}`
        }
    }
}
