import {Detail, DetailProps} from "components/common";
import {IHome, IHomeFormValues, DetailsSettings} from "features/homes";

interface Props extends Omit<DetailProps<IHome, IHomeFormValues>, 'settings' | 'data'> {
    data?: IHome
}

export function HomeDetails({data, ...rest}: Props) {
    return (
        <Detail
            {...rest}
            data={data}
            settings={DetailsSettings()}/>
    )
}