import { ModelRoutes, ModelApiService } from "services/strapi"
import { ITask } from "features/tasks"
import { strapi } from "lib/strapi-api"
import axios from "axios"

class TaskApiSingleton extends ModelApiService<ITask> {
  async getEmbeddedDocumentUrl(
    taskId: number | undefined
  ): Promise<string | undefined> {
    if (!taskId) return undefined
    try {
      const res = await strapi.get(`/tasks/${taskId}/embedded-document-url`)
      return res.data as string
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(
          `[getEmbeddedDocumentUrl] Error in route: ${this.route}`,
          e.message
        )
      }
      return undefined
    }
  }
}

export const taskApi = new TaskApiSingleton(ModelRoutes.Tasks)
