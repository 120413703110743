import { ModelRoutes, ModelApiService } from "services/strapi"
import { IOffer } from "features/offers"
import { strapi } from "lib/strapi-api"
import axios from "axios"

class OfferApiSingleton extends ModelApiService<IOffer> {
  async getEmbeddedDocumentUrl(
    offerId: number | undefined
  ): Promise<string | undefined> {
    if (!offerId) return undefined
    try {
      const res = await strapi.get(`/offers/${offerId}/embedded-document-url`)
      return res.data as string
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.error(
          `[getEmbeddedDocumentUrl] Error in route: ${this.route}`,
          e.message
        )
      }
      return undefined
    }
  }
}

export const offerApi = new OfferApiSingleton(ModelRoutes.Offers)
