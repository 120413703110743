import {useEffect, useState} from "react";
import {RoutesResolver} from "routes/routes.resolver";
import {authHooks} from "features/auth";

export function useUserRoutes(){
    const {data:user, isSuccess} = authHooks.useMe()
    const [routesResolver, setRoutesResolver] = useState<RoutesResolver>(new RoutesResolver());

    useEffect(() => {
        if (user) {
            setRoutesResolver(new RoutesResolver())
        }
    }, [user, isSuccess])

    return {
        routesResolver
    }
}