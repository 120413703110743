import React from 'react';
import {Text, useMantineTheme, Stack} from "@mantine/core";
import Logo from "assets/logo.png"

interface Props {
    text: string,
}

export function LoaderDefault(props: Props) {
    const {text} = props;
    const theme = useMantineTheme();

    return (
        <Stack spacing={0} p={16} align="center">
            <svg
                width="54"
                height="54"
                viewBox="0 0 38 38"
                xmlns="http://www.w3.org/2000/svg"
                stroke={theme.colors.red[6]}
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(1 1)" strokeWidth="2">
                        <circle strokeOpacity=".5" cx="18" cy="18" r="18"/>
                        <path d="M36 18c0-9.94-8.06-18-18-18">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 18 18"
                                to="360 18 18"
                                dur="0.65s"
                                repeatCount="indefinite"
                            />
                        </path>

                    </g>
                </g>
                <image href={Logo} height={22} width={22} x="50%" y="50%"
                       transform="translate(-11,-11)"/>
            </svg>
            <Text mt={9} size={14} weight={600} gradient={{
                from: 'red',
                to: 'orange',
                deg: 45
            }} variant="gradient" color="dimmed">
                {text}
            </Text>
        </Stack>
    )
}
