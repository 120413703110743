import { ListItemsTableSettings } from "components/common"
import { IOffer, IOfferFormValues } from "features/offers"
import { ActionIcon, Center, Text } from "@mantine/core"
import { RoutesResolver } from "routes"
import dayjs from "dayjs"
import { IconDownload } from "@tabler/icons"
import { BrowserUtils, NumberUtils, StringUtils } from "utils"

interface TableSettingProps {
  routesResolver: RoutesResolver
}

export type TTableSettings = (
  props: TableSettingProps
) => ListItemsTableSettings<IOffer & { id: number }, IOfferFormValues>[]

export const TableSettings: TTableSettings = () => [
  {
    key: "status",
    label: "Status",
    isNotFormValue: true,
    required: false,
    Render: {
      Static: function ({ data }) {
        const { status } = data
        return <Text>{StringUtils.capitalizeFirstLetter(status)}</Text>
      },
    },
  },
  {
    key: "amount",
    label: "Offer Amount",
    isNotFormValue: true,
    required: false,
    Render: {
      Static: function ({ data }) {
        const { amount } = data
        return <Text>{NumberUtils.formatAsCurrency(amount)}</Text>
      },
    },
  },
  {
    key: "offererName",
    label: "Offer By",
    isNotFormValue: true,
    required: false,
    Render: {
      Static: function ({ data }) {
        const { offererName } = data
        return <Text>{offererName}</Text>
      },
    },
  },
  {
    key: "expiresOn",
    label: "Expires On",
    isNotFormValue: true,
    required: false,
    Render: {
      Static: function ({ data }) {
        const { expiresOn } = data
        return (
          <Text>
            {expiresOn ? dayjs(expiresOn).format("MMM DD, YYYY") : ""}
          </Text>
        )
      },
    },
  },
  {
    key: "rejectionDetails",
    label: "Rejection Details",
    isNotFormValue: true,
    required: false,
    Render: {
      Static: function ({ data }) {
        const { rejectionDetails } = data
        return <Text>{rejectionDetails}</Text>
      },
    },
  },
  {
    key: "counterDetails",
    label: "Counter Offer Details",
    isNotFormValue: true,
    required: false,
    Render: {
      Static: function ({ data }) {
        const { counterDetails } = data
        return <Text>{counterDetails}</Text>
      },
    },
  },
  {
    key: "offerDocument",
    label: "Offer Document",
    isNotFormValue: true,
    required: false,
    Render: {
      Static: function ({ data }) {
        const { offerDocument } = data
        if (!offerDocument || !offerDocument.data) return null
        return (
          <Center>
            <ActionIcon
              title="Download offer document"
              onClick={() => {
                BrowserUtils.downloadFile(
                  offerDocument.data?.attributes.name,
                  offerDocument.data?.attributes.url
                )
              }}
            >
              <IconDownload></IconDownload>
            </ActionIcon>
          </Center>
        )
      },
    },
  },
]
