import { Button, Group, Stack, Text, Textarea } from "@mantine/core"
import { useState } from "react"
import { StringUtils } from "utils"

export interface Props {
  onConfirm: (counterDetails: string) => void
  onClose: () => void
}

export function OffersCounterOfferModal({ onConfirm, onClose }: Props) {
  const [counterDetails, setCounterDetails] = useState("")
  const [counterDetailsError, setCounterDetailsError] = useState("")

  async function onClickCounterOffer() {
    if (StringUtils.isNullOrWhiteSpace(counterDetails)) {
      setCounterDetailsError("Please provide details for the counter offer")
      return
    }

    await onConfirm(counterDetails)
  }

  return (
    <Stack>
      <Text>
        Tell us how we should modify the offer to better meet your needs and
        your concierge will communicate this to the offerer. You will need to
        sign a counter offer document to finalize the counter offer, which your
        concierge will prepare for you after you submit this counter offer.
      </Text>
      <Textarea
        required
        label="Required"
        onChange={(event) => setCounterDetails(event.currentTarget.value)}
      />
      {counterDetailsError && <Text color="red">{counterDetailsError}</Text>}
      <Group position="right">
        <Button color="gray" variant="outline" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={onClickCounterOffer}
          disabled={
            counterDetailsError !== "" ||
            StringUtils.isNullOrWhiteSpace(counterDetails)
          }
        >
          Counter Offer
        </Button>
      </Group>
    </Stack>
  )
}
