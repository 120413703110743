import { showNotification } from "@mantine/notifications"

export class ErrorUtils {
  static handleError(title: string, message: string, error?: any) {
    showNotification({
      title: title ? title : "Application Error",
      message: message
        ? `${message}. Please contact us at contact@behoused.com.`
        : "An error occurred. Please contact us at contact@behoused.com.",
      color: "red",
      autoClose: 1000 * 10,
    })
    if (error) console.error(`[Application Error] ${title}`, error)
  }
}
