import { ModelHooks } from "services/strapi"
import { offerApi } from "../services/offer.strapi.api"
import { IOffer } from "../types/offer.types"
import { useQuery } from "@tanstack/react-query"

class OfferHooks extends ModelHooks<IOffer> {
  useGetEmbeddedDocumentUrl = (offerId: number | undefined) => {
    return useQuery(
      [this.modelQueryKey, offerId, "embedded-document-url"],
      () => offerApi.getEmbeddedDocumentUrl(offerId),
      { enabled: !!offerId }
    )
  }
}

export const offerHooks = new OfferHooks(offerApi)
