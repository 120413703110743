export enum RouteSegments {
  Dashboard = "dashboard",
  Settings = "settings",
  Users = "users",
  Organizations = "orgs",
  Auth = "auth",
  Login = "login",
  Register = "register",
  ForgotPassword = "forgot-password",
  AcceptInvitation = "accept-invitation",
  ResetPassword = "reset-password",
  Admin = "admin",
  Services = "services",
  Details = "details",
  Today = "today",
  Wildcard = "*",
  Root = "/",
  Create = "create",
  RecentlySoldNearYou = "recently-sold-near-you",
  EmbeddedDocument = "embedded-document",
  Signed = "signed",
  EmbeddedDocumentSigned = "embedded-document-signed",
  Forms = "forms",
  HomeListingPaymentConfirmation = "home-listing-payment-confirmation",

  /* Generated Route Segments */
  EmbeddedDocuments = "embedded-documents",
  TaskSteps = "task-steps",
  DocumentFolders = "document-folders",
  Documents = "documents",
  Offers = "offers",
  Concierges = "concierges",
  Tasks = "tasks",
  Homes = "homes",
}

export enum RouteParams {
  UserId = ":userId",
  ClerkUserId = ":clerkUserId",
  /* Generated Route Params */
  EmbeddedDocumentId = ":embeddedDocumentId",
  TaskStepId = ":taskStepId",
  DocumentFolderId = ":documentFolderId",
  DocumentId = ":documentId",
  OfferId = ":offerId",
  ConciergeId = ":conciergeId",
  TaskId = ":taskId",
  HomeId = ":homeId",
  HomeTab = ":homeTab",
}
