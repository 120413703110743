import { useParams } from "react-router-dom"
import {
  HomeDetails,
  HomeUtils,
  IHomeFormValues,
  homeHooks,
} from "features/homes"
import { Content } from "components/common"
import { useForm } from "@mantine/form"
import { useEffect, useState } from "react"
import { ActionIcon, Button, Group } from "@mantine/core"
import { IconDeviceFloppy, IconEdit } from "@tabler/icons"

export function HomeSettings() {
  const { homeId } = useParams()
  const [showForm, setShowForm] = useState<boolean>(false)

  const { isLoading: isLoadingHome, data: home } = homeHooks.useGetById(
    parseInt(homeId || ""),
    {
      populate: "*",
    }
  )

  const { mutateAsync: updateHome, isLoading: isUpdatingHome } =
    homeHooks.useUpdate()

  const homeForm = useForm<IHomeFormValues>({
    validateInputOnChange: true,
    validate: HomeUtils.getValidationSchema(),
    initialValues: HomeUtils.transformInitialValues(),
  })

  async function onEditHome() {
    if (!homeForm.validate().hasErrors) {
      await updateHome({
        values: homeForm.values,
        id: parseInt(homeId || ""),
      })
      setShowForm(false)
    }
  }

  useEffect(() => {
    if (home) {
      setShowForm(false)
      homeForm.setValues(HomeUtils.transformInitialValues(home?.data))
    }
  }, [home])

  return (
    <Content.Body mt={12}>
      <Content.Section p={16}>
        <Content.Title mb={12}>
          <Group position={"apart"}>
            House Settings
            {!showForm && (
              <ActionIcon>
                <IconEdit
                  onClick={() => {
                    setShowForm(true)
                  }}
                />
              </ActionIcon>
            )}
            {showForm && (
              <Group>
                <Button
                  onClick={() => {
                    setShowForm(false)
                  }}
                  disabled={isUpdatingHome}
                >
                  Cancel
                </Button>
                <Button
                  onClick={onEditHome}
                  leftIcon={<IconDeviceFloppy />}
                  loading={isUpdatingHome}
                >
                  Update
                </Button>
              </Group>
            )}
          </Group>
        </Content.Title>
        <HomeDetails
          disableFields={[
            "attomId",
            "zillowId",
            "apn",
            "estimatedValue",
            "estimatedRentalValue",
            "user",
            "hoaType",
          ]}
          loading={isLoadingHome}
          data={home?.data.attributes}
          asForm={showForm}
          form={homeForm}
        />
      </Content.Section>
    </Content.Body>
  )
}
