import { IStrapiEntity } from "services/strapi"
import { Button, Group, Loader, Stack, Table, Text } from "@mantine/core"
import { IconDownload, IconFile, IconFolder, IconUpload } from "@tabler/icons"
import { NoData } from "components/common"
import { useState } from "react"
import { IDocument, DocumentsFileUploadForm } from "features/documents"
import { ArrowLeft } from "tabler-icons-react"
import { IDocumentFolder } from "features/document-folders"
import { useModals } from "@mantine/modals"
import { BrowserUtils } from "utils"

interface Props {
  data?: IStrapiEntity<IDocument>[]
  loading?: boolean
  onBackClick?: () => void
  folder?: IStrapiEntity<IDocumentFolder>
  homeId?: string
}

export function DocumentsFolderView({
  data,
  loading,
  onBackClick,
  folder,
  homeId,
}: Props) {
  const modals = useModals()
  const [hoveredDocument, setHoveredDocument] = useState<number | null>(null)

  const renderContent = () => {
    if (loading) {
      return (
        <Table>
          <tbody>
            <tr>
              <td>
                <Group noWrap>
                  <Loader size="sm" />
                  <Text>Loading...</Text>
                </Group>
              </td>
            </tr>
          </tbody>
        </Table>
      )
    }

    if (data?.length === 0) {
      return <NoData title="No Data" />
    }

    return (
      <Table>
        <thead>
          <tr>
            <th>File Name</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((document) => (
            <tr
              key={document?.id}
              style={{ cursor: "pointer" }}
              onMouseEnter={() => setHoveredDocument(document.id)}
              onMouseLeave={() => setHoveredDocument(null)}
              onClick={() =>
                BrowserUtils.downloadFile(
                  document?.attributes?.file?.data?.attributes?.name,
                  document?.attributes?.file?.data?.attributes?.url
                )
              }
            >
              <td>
                <Group position="apart">
                  <Group spacing={8}>
                    <IconFile size={18} />
                    <Text underline={hoveredDocument === document.id}>
                      {document?.attributes?.file?.data?.attributes?.name}
                    </Text>
                  </Group>
                  <IconDownload size={18} />
                </Group>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  const onUploadButtonClick = () => {
    modals.openModal({
      withCloseButton: true,
      closeOnClickOutside: true,
      closeOnEscape: true,
      centered: true,
      size: "900",
      overflow: "outside",
      title: <Text size={"xl"}>Upload Document</Text>,
      children: (
        <DocumentsFileUploadForm
          homeId={homeId}
          folderId={folder?.attributes?.folderId}
          onUpload={onUpload}
        />
      ),
    })
  }

  async function onUpload() {
    modals.closeAll()
  }

  return (
    <Stack>
      <Group position={"apart"}>
        <Group>
          <Button onClick={onBackClick} leftIcon={<ArrowLeft />}>
            Back
          </Button>
          {folder && (
            <Text size="xl" weight={700}>
              <Group spacing={8}>
                <IconFolder size={18} />
                {folder?.attributes?.name} Documents
              </Group>
            </Text>
          )}
        </Group>
        <Group>
          <Button
            rightIcon={<IconUpload size={18} />}
            onClick={onUploadButtonClick}
          >
            Upload
          </Button>
        </Group>
      </Group>
      {renderContent()}
    </Stack>
  )
}
