import { DetailSettings } from "components/common"
import { NumberInput, Select, Text, TextInput } from "@mantine/core"
import { IHome, IHomeFormValues } from "features/homes"

export type TDetailsSettings = () => DetailSettings<IHome, IHomeFormValues>[]
export const DetailsSettings: TDetailsSettings = () => [
  {
    key: "attomId",
    label: "Attom Id",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { attomId } = data
        return <Text size="sm">{attomId}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <TextInput
            placeholder="attomId"
            error={!form.isValid("attomId")}
            disabled={disabled}
            {...form.getInputProps("attomId")}
          />
        )
      },
    },
  },

  {
    key: "zillowId",
    label: "Zillow Id",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { zillowId } = data
        return <Text size="sm">{zillowId}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <TextInput
            placeholder="zillowId"
            error={!form.isValid("zillowId")}
            disabled={disabled}
            {...form.getInputProps("zillowId")}
          />
        )
      },
    },
  },

  {
    key: "apn",
    label: "Apn",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { apn } = data
        return <Text size="sm">{apn}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <TextInput
            placeholder="apn"
            error={!form.isValid("apn")}
            disabled={disabled}
            {...form.getInputProps("apn")}
          />
        )
      },
    },
  },

  {
    key: "streetAddress",
    label: "Street Address",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { streetAddress } = data
        return <Text size="sm">{streetAddress}</Text>
      },
      Form: function ({ form }) {
        return (
          <TextInput
            placeholder="streetAddress"
            error={!form.isValid("streetAddress")}
            disabled={true}
            {...form.getInputProps("streetAddress")}
          />
        )
      },
    },
  },

  {
    key: "city",
    label: "City",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { city } = data
        return <Text size="sm">{city}</Text>
      },
      Form: function ({ form }) {
        return (
          <TextInput
            placeholder="city"
            error={!form.isValid("city")}
            disabled={true}
            {...form.getInputProps("city")}
          />
        )
      },
    },
  },

  {
    key: "state",
    label: "State",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { state } = data
        return <Text size="sm">{state}</Text>
      },
      Form: function ({ form }) {
        return (
          <TextInput
            placeholder="state"
            error={!form.isValid("state")}
            disabled={true}
            {...form.getInputProps("state")}
          />
        )
      },
    },
  },

  {
    key: "zip",
    label: "Zip",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { zip } = data
        return <Text size="sm">{zip}</Text>
      },
      Form: function ({ form }) {
        return (
          <TextInput
            placeholder="zip"
            error={!form.isValid("zip")}
            disabled={true}
            {...form.getInputProps("zip")}
          />
        )
      },
    },
  },

  {
    key: "beds",
    label: "Beds",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { beds } = data
        return <Text size="sm">{beds}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <NumberInput
            placeholder="Number of bedrooms"
            error={!form.isValid("beds")}
            disabled={disabled}
            max={10}
            min={0}
            {...form.getInputProps("beds")}
          />
        )
      },
    },
  },

  {
    key: "fullBaths",
    label: "Full Baths",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { fullBaths } = data
        return <Text size="sm">{fullBaths}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <NumberInput
            placeholder="Number of full bathrooms"
            error={!form.isValid("fullBaths")}
            disabled={disabled}
            max={10}
            min={0}
            {...form.getInputProps("fullBaths")}
          />
        )
      },
    },
  },

  {
    key: "partialBaths",
    label: "Partial Baths",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { partialBaths } = data
        return <Text size="sm">{partialBaths}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <NumberInput
            placeholder="number of partial bathrooms"
            error={!form.isValid("partialBaths")}
            disabled={disabled}
            max={10}
            min={0}
            {...form.getInputProps("partialBaths")}
          />
        )
      },
    },
  },

  {
    key: "squareFootage",
    label: "Square Footage",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { squareFootage } = data
        return <Text size="sm">{squareFootage}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <NumberInput
            placeholder="Total square footage"
            error={!form.isValid("squareFootage")}
            disabled={disabled}
            min={0}
            {...form.getInputProps("squareFootage")}
          />
        )
      },
    },
  },

  {
    key: "yearBuilt",
    label: "Year Built",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { yearBuilt } = data
        return <Text size="sm">{yearBuilt}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <NumberInput
            placeholder="Year property was built"
            error={!form.isValid("yearBuilt")}
            disabled={disabled}
            min={1900}
            max={new Date().getFullYear() + 1}
            {...form.getInputProps("yearBuilt")}
          />
        )
      },
    },
  },

  {
    key: "floors",
    label: "Floors",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { floors } = data
        return <Text size="sm">{floors}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <NumberInput
            placeholder="Number of floors in property"
            error={!form.isValid("floors")}
            disabled={disabled}
            min={0}
            max={10}
            {...form.getInputProps("floors")}
          />
        )
      },
    },
  },

  {
    key: "basementType",
    label: "Basement Type",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { basementType } = data
        return <Text size="sm">{basementType}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <Select
            placeholder="Is the basement finished?"
            error={!form.isValid("basementType")}
            disabled={disabled}
            {...form.getInputProps("basementType")}
            data={[
              { label: "Finished", value: "finished" },
              { label: "Unfinished", value: "unfinished" },
              { label: "None", value: "none" },
            ]}
          />
        )
      },
    },
  },

  {
    key: "basementSquareFootage",
    label: "Basement Square Footage",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { basementSquareFootage } = data
        return <Text size="sm">{basementSquareFootage}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <NumberInput
            placeholder="Total square footage of the basement"
            error={!form.isValid("basementSquareFootage")}
            disabled={disabled}
            min={0}
            max={10000}
            {...form.getInputProps("basementSquareFootage")}
          />
        )
      },
    },
  },

  {
    key: "hasHoa",
    label: "Does your neighborhood have an HOA?",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { hasHoa } = data
        return <Text size="sm">{hasHoa ? "Yes" : "No"}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <Select
            placeholder="Does your neighborhood have an HOA?"
            error={!form.isValid("hasHoa")}
            disabled={disabled}
            {...form.getInputProps("hasHoa")}
            data={[
              { label: "Yes", value: "true" },
              { label: "No", value: "false" },
            ]}
          />
        )
      },
    },
  },

  {
    key: "hoaType",
    label: "Hoa Type",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { hoaType } = data
        return <Text size="sm">{hoaType}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <TextInput
            placeholder="hoaType"
            error={!form.isValid("hoaType")}
            disabled={disabled}
            {...form.getInputProps("hoaType")}
          />
        )
      },
    },
  },

  {
    key: "parkingType",
    label: "Parking Type",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { parkingType } = data
        return <Text size="sm">{parkingType}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <TextInput
            placeholder="Type of parking available, eg. garage, driveway, etc."
            error={!form.isValid("parkingType")}
            disabled={disabled}
            {...form.getInputProps("parkingType")}
          />
        )
      },
    },
  },

  {
    key: "parkingSpaces",
    label: "Parking Spaces",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { parkingSpaces } = data
        return <Text size="sm">{parkingSpaces}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <NumberInput
            placeholder="Number of parking spaces based on parking type"
            error={!form.isValid("parkingSpaces")}
            disabled={disabled}
            min={0}
            max={100}
            {...form.getInputProps("parkingSpaces")}
          />
        )
      },
    },
  },

  {
    key: "hasSolar",
    label: "Has Solar",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { hasSolar } = data
        return <Text size="sm">{hasSolar ? "Yes" : "No"}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <Select
            placeholder="Does the home have solar panels?"
            error={!form.isValid("hasSolar")}
            disabled={disabled}
            {...form.getInputProps("hasSolar")}
            data={[
              { label: "Yes", value: "true" },
              { label: "No", value: "false" },
            ]}
          />
        )
      },
    },
  },

  {
    key: "estimatedValue",
    label: "Estimated Value",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { estimatedValue } = data
        return <Text size="sm">{estimatedValue}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <TextInput
            placeholder="estimatedValue"
            error={!form.isValid("estimatedValue")}
            disabled={disabled}
            {...form.getInputProps("estimatedValue")}
          />
        )
      },
    },
  },

  {
    key: "estimatedRentalValue",
    label: "Estimated Rental Value",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { estimatedRentalValue } = data
        return <Text size="sm">{estimatedRentalValue}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <TextInput
            placeholder="estimatedRentalValue"
            error={!form.isValid("estimatedRentalValue")}
            disabled={disabled}
            {...form.getInputProps("estimatedRentalValue")}
          />
        )
      },
    },
  },

  {
    key: "user",
    label: "Users",
    required: false,
    Render: {
      Static: function ({ data }) {
        const { user } = data
        return <Text size="sm">{user?.data?.id}</Text>
      },
      Form: function ({ form, disabled }) {
        return (
          <TextInput
            placeholder="user"
            error={!form.isValid("user")}
            disabled={disabled}
            {...form.getInputProps("user")}
          />
        )
      },
    },
  },
]
