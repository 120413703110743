import { QueryClient } from "@tanstack/react-query"
import { ModelRoutes } from "services/strapi"

export class ApiUtils {
  static async handleSuccess(
    queryClient: QueryClient,
    modelQueryKey: any,
    extraInvalidateKeys: ModelRoutes[] = []
  ) {
    for (let i = 0; i < extraInvalidateKeys.length; i++) {
      const extraInvalidateKey = extraInvalidateKeys[i]
      await queryClient.invalidateQueries({
        queryKey: [extraInvalidateKey],
      })
    }
    await queryClient.invalidateQueries([modelQueryKey])
  }
}
