import { Content, Page } from "components/common"
import { Button, Group, Stack, Text, Textarea } from "@mantine/core"
import { DefaultPageWrapper } from "layouts"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
  EOfferStatus,
  IOffer,
  offerHooks,
  OffersCounterOfferModal,
  OffersListTable,
} from "features/offers"
import { useUserRoutes } from "routes"
import { IconArrowLeft } from "@tabler/icons"
import { useMemo, useState } from "react"
import { useModals } from "@mantine/modals"
import { ModelRoutes } from "services/strapi"

export function OffersPage() {
  const navigate = useNavigate()
  const { routesResolver } = useUserRoutes()
  const { homeId } = useParams()
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get("taskId")
  const modals = useModals()

  const [rejectionDetails, setRejectionDetails] = useState("")

  const { isLoading: isLoadingOffers, data: offers } = offerHooks.useGet({
    filters: {
      home: parseInt(homeId || ""),
    },
    populate: "*",
    pagination: {
      page: 1,
      pageSize: 250,
    },
  })

  const { mutateAsync: updateOffer } = offerHooks.useUpdate(
    [ModelRoutes.Tasks],
    {
      taskId: taskId ? parseInt(taskId) : undefined,
    }
  )

  async function onClickAccept(offerId: number) {
    modals.openConfirmModal({
      withCloseButton: true,
      closeOnClickOutside: true,
      closeOnEscape: true,
      centered: true,
      overflow: "outside",
      title: <Text size={"xl"}>🥳 Ready to accept this offer?</Text>,
      children: (
        <Text>
          After you accept, you will also need to schedule an offer acceptance
          meeting with your concierge.
        </Text>
      ),
      labels: {
        confirm: <Text>🎉 Accept Offer</Text>,
        cancel: "Cancel",
      },
      //onCancel: () => console.log('Cancel'),
      onConfirm: async () => {
        const response = await updateOffer({
          id: offerId,
          values: {
            status: EOfferStatus.ACCEPTED,
          },
        })
        if (response) navigate(routesResolver.getHomeDetailsById(homeId || ""))
      },
    })
  }

  async function onClickReject(offerId: number) {
    modals.openConfirmModal({
      withCloseButton: true,
      closeOnClickOutside: true,
      closeOnEscape: true,
      centered: true,
      overflow: "outside",
      title: (
        <Text size={"xl"}>Please tell us why you are rejecting this offer</Text>
      ),
      children: (
        <Stack>
          <Text>
            This is optional, but it can help us better understand your needs
            and preferences. This information will not be shared with the
            offerer.
          </Text>
          <Textarea
            onChange={(event) => setRejectionDetails(event.currentTarget.value)}
          />
        </Stack>
      ),
      labels: {
        confirm: <Text>Reject Offer</Text>,
        cancel: "Cancel",
      },
      //onCancel: () => console.log('Cancel'),
      onConfirm: async () => {
        await updateOffer({
          id: offerId,
          values: {
            status: EOfferStatus.REJECTED,
            rejectionDetails,
          },
        })
        window.location.reload()
      },
    })
  }

  async function onClickCounter(offerId: number) {
    modals.openModal({
      withCloseButton: true,
      closeOnClickOutside: true,
      closeOnEscape: true,
      centered: true,
      overflow: "outside",
      title: <Text size={"xl"}>Counter Offer</Text>,
      children: (
        <OffersCounterOfferModal
          onConfirm={(counterDetails) =>
            onCounterOfferSubmited(offerId, counterDetails)
          }
          onClose={modals.closeAll}
        />
      ),
    })
  }

  async function onCounterOfferSubmited(
    offerId: number,
    counterDetails: string
  ) {
    const response = await updateOffer({
      id: offerId,
      values: {
        status: EOfferStatus.COUNTERED,
        counterDetails,
      },
    })
    if (response) {
      window.location.reload()
      modals.closeAll()
    }
  }

  async function onClickSignCounterOfferDocument(offerId: number) {
    navigate(
      routesResolver.getEmbeddedDocumentByHomeAndOfferId(
        homeId || "",
        offerId,
        taskId || ""
      )
    )
  }

  const hasAcceptedOffer = useMemo(
    () =>
      offers?.data?.some(
        (offer) => offer.attributes.status === EOfferStatus.ACCEPTED
      ),
    [offers, isLoadingOffers]
  )

  const renderAcceptButton = (offerId: number) => (
    <Button onClick={() => onClickAccept(offerId)} color="teal">
      Accept
    </Button>
  )

  const renderRejectButton = (offerId: number) => (
    <Button onClick={() => onClickReject(offerId)} color="red">
      Reject
    </Button>
  )

  const renderCounterButton = (offerId: number) => (
    <Button onClick={() => onClickCounter(offerId)} color="blue">
      Counter
    </Button>
  )

  // compare offer expiresOn to current date
  const isOfferExpired = (offer: IOffer) => {
    if (!offer.expiresOn) {
      return false
    }

    return new Date(offer.expiresOn) < new Date()
  }

  const renderOfferActions = () => {
    if (hasAcceptedOffer) {
      return []
    }

    return [
      {
        key: "actions",
        label: "What would you like to do?",
        Render: {
          Static: function ({
            data,
          }: {
            data: IOffer & {
              id: number
            }
          }) {
            return (
              <>
                {data.status === EOfferStatus.PENDING &&
                  !isOfferExpired(data) && (
                    <Group>
                      {renderAcceptButton(data.id)}
                      {renderRejectButton(data.id)}
                      {renderCounterButton(data.id)}
                    </Group>
                  )}
                {data.status === EOfferStatus.COUNTERED &&
                  !isOfferExpired(data) && (
                    <Button
                      onClick={() => onClickSignCounterOfferDocument(data.id)}
                      color="blue"
                    >
                      Sign Counter Offer Document
                    </Button>
                  )}
              </>
            )
          },
        },
      },
    ]
  }

  const sortedOffers = useMemo(() => {
    if (!offers || !offers.data) {
      return []
    }

    return offers?.data?.sort((a, b) => {
      const statuses = [
        EOfferStatus.ACCEPTED,
        EOfferStatus.PENDING,
        EOfferStatus.COUNTERED,
        EOfferStatus.COUNTER_SIGNED,
        EOfferStatus.REJECTED,
        EOfferStatus.EXPIRED,
        EOfferStatus.CANCELLED,
      ]
      return (
        statuses.indexOf(a.attributes.status) -
        statuses.indexOf(b.attributes.status)
      )
    })
  }, [offers, isLoadingOffers])

  return (
    <DefaultPageWrapper>
      <Page.Header>
        <Group>
          <Button
            leftIcon={<IconArrowLeft />}
            onClick={() =>
              navigate(routesResolver.getHomeDetailsById(homeId || ""))
            }
          >
            Back
          </Button>
        </Group>
      </Page.Header>
      <Page.Body>
        <Content.Section>
          <Content.Header>
            <Content.Title>Your Offers</Content.Title>
          </Content.Header>
          <Content.Body>
            <OffersListTable
              addFields={renderOfferActions()}
              loading={isLoadingOffers}
              data={sortedOffers}
            />
          </Content.Body>
        </Content.Section>
      </Page.Body>
    </DefaultPageWrapper>
  )
}
