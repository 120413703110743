import React from 'react';
import {Group, Stack, ScrollArea, useMantineTheme} from "@mantine/core";
import {Outlet,} from "react-router-dom";
import {ThemeManager} from "utils";
import {getHeightForScroll} from "settings";

export function AdminWrapper() {
    const theme = useMantineTheme();

    return (
        <Stack spacing={0}>
            <Group align="flex-start" position="left" spacing={0} noWrap>
                <Stack style={ {width: "100%"} } spacing={0}>
                    <ScrollArea sx={() => ({
                        height: getHeightForScroll(),
                        backgroundColor: ThemeManager.chooseColor(theme, false, theme.colors.gray[0], theme.colors.dark[8])
                    })}>
                        <Outlet/>
                    </ScrollArea>
                </Stack>
            </Group>
        </Stack>
    );
}
