export class BrowserUtils {
  static downloadFile(fileName?: string, url?: string) {
    if (!fileName || !url) return

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob)
        const link = document.createElement("a")
        link.href = url
        link.download = fileName
        link.target = "_blank"
        link.click()
        URL.revokeObjectURL(url)
      })
  }

  static downloadBase64File(
    base64EncodedContent: any,
    fileName: string,
    contentType: string
  ) {
    const linkSource = `data:${contentType};base64,${base64EncodedContent}`
    const downloadLink = document.createElement("a")
    downloadLink.href = linkSource
    downloadLink.download = fileName
    downloadLink.click()
  }
}
