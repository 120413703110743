import { ModelHooks } from "services/strapi"
import { embeddedDocumentApi } from "../services/embedded-document.strapi.api"
import { IEmbeddedDocument } from "../types/embedded-document.types"
import { useQuery } from "@tanstack/react-query"

class EmbeddedDocumentHooks extends ModelHooks<IEmbeddedDocument> {
  useGetByAuthCode = (authCode: string | null) => {
    return useQuery([this.modelQueryKey, "get-by-auth-code"], () =>
      embeddedDocumentApi.getEmbeddedDocumentByAuthCode(authCode)
    )
  }
}

export const embeddedDocumentHooks = new EmbeddedDocumentHooks(
  embeddedDocumentApi
)
