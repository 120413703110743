import { ITask } from "features/tasks"

import { Button } from "@mantine/core"
import { IStrapiEntity } from "services/strapi"
import { IHome } from "features/homes"
import { IconLink } from "@tabler/icons"
import { useNavigate } from "react-router-dom"
import { useUserRoutes } from "routes"

interface Props {
  home?: IStrapiEntity<IHome>
  task?: IStrapiEntity<ITask>
  onTaskComplete?: () => void
}

export function TaskLinkFormButton({ task, home }: Props) {
  const navigate = useNavigate()
  const { routesResolver } = useUserRoutes()

  if (!task) return null

  function onClick() {
    if (!task || !home) return

    navigate(
      routesResolver.getInteractiveFormByHomeId(
        home.id,
        task?.attributes.formId,
        task.id
      )
    )
  }

  return (
    <Button leftIcon={<IconLink />} onClick={onClick}>
      Link to Form
    </Button>
  )
}
