import { useParams } from "react-router-dom"
import { IUser, userHooks } from "features/users"
import { authHooks } from "features/auth"

export interface UseCurrentUserReturn {
  user?: IUser | null
}

export function useCurrentUser(): UseCurrentUserReturn {
  const { clerkUserId } = useParams()

  const { data: clerkUser } = userHooks.useGetByClerkUserId(clerkUserId || "")
  const { data: me } = authHooks.useMe()

  return { user: clerkUserId ? clerkUser : me }
}
