import { ModelHooks } from "services/strapi"
import { taskApi } from "../services/task.strapi.api"
import { ITask } from "../types/task.types"
import { useQuery } from "@tanstack/react-query"

class TaskHooks extends ModelHooks<ITask> {
  useGetEmbeddedDocumentUrl = (taskId: number | undefined) => {
    return useQuery(
      [this.modelQueryKey, taskId, "embedded-document-url"],
      () => taskApi.getEmbeddedDocumentUrl(taskId),
      { enabled: !!taskId }
    )
  }
}

export const taskHooks = new TaskHooks(taskApi)
