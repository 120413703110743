import { Box, Button, Select, Stack, Title } from "@mantine/core"
import { isNotEmpty, useForm } from "@mantine/form"
import { IconArrowLeft, IconArrowRight } from "@tabler/icons"
import { Content } from "components/common"
import {
  IHomeFormScheduleSignLockboxDeliveryFormValues,
  homeHooks,
} from "features/homes"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useUserRoutes } from "routes"
import { ErrorUtils } from "utils/error"

export function HomeFormScheduleSignLockboxDeliveryPage() {
  const { homeId } = useParams()
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get("taskId")

  const navigate = useNavigate()
  const { routesResolver } = useUserRoutes()

  const { mutateAsync } = homeHooks.useSubmitScheduleSignLockboxDelivery()

  const form = useForm<IHomeFormScheduleSignLockboxDeliveryFormValues>({
    validate: {
      schedule: isNotEmpty("This field is required"),
    },
    validateInputOnChange: true,
    initialValues: {
      id: parseInt(homeId || ""),
      taskId: parseInt(taskId || ""),
      schedule: "",
    },
  })

  async function onSubmit() {
    if (!homeId) return
    if (!form.validate().hasErrors) {
      const payload: IHomeFormScheduleSignLockboxDeliveryFormValues = {
        id: parseInt(homeId),
        taskId: form.values.taskId,
        schedule: form.values.schedule,
      }

      try {
        await mutateAsync(payload)
        navigate(routesResolver.getHomeDetailsById(parseInt(homeId || "")))
      } catch (error) {
        ErrorUtils.handleError(
          "Error submitting form",
          "We apologize, something went wrong submitting your form",
          error
        )
      }
    }
  }

  return (
    <Content.Body>
      <Stack>
        <Box>
          <Button
            leftIcon={<IconArrowLeft />}
            onClick={() =>
              navigate(
                routesResolver.getHomeDetailsById(parseInt(homeId || ""))
              )
            }
          >
            Back
          </Button>
        </Box>
        <Title>Review Pricing</Title>
        <form>
          <Stack spacing={"lg"}>
            <Select
              label="Did you review schedule?"
              required
              data={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
              {...form.getInputProps("schedule")}
            />
          </Stack>
          <Button rightIcon={<IconArrowRight />} mt={"lg"} onClick={onSubmit}>
            Submit
          </Button>
        </form>
      </Stack>
    </Content.Body>
  )
}
