import {IStrapiEntity, IStrapiFlatEntity} from "services/strapi";

export class StrapiUtils {
    static flattenStrapiEntity<T>(entity: IStrapiEntity<T>): IStrapiFlatEntity<T> {
        return {...entity.attributes, id: entity.id}
    }

    static flattenStrapiEntities<T>(entities?: IStrapiEntity<T>[]): IStrapiFlatEntity<T>[] | undefined {
        if (entities) {
            return entities.map(entity => StrapiUtils.flattenStrapiEntity(entity))
        } else {
            return undefined
        }
    }

    static serializeToStrapiFormData<TValues>(
        values: Partial<Record<keyof TValues, TValues[keyof TValues]>>,
        fileFields: (keyof TValues)[]
    ): FormData {
        const formData = new FormData()
        const data: Partial<Record<keyof TValues, TValues[keyof TValues]>> = {}
        const keys = Object.keys(values) as (keyof TValues)[]
        keys.forEach((key: keyof TValues) => {
            const value = values[key as keyof TValues]
            if (fileFields.indexOf(key) !== -1) {
                const files = value as File[] | File
                if (files) {
                    if (Array.isArray(value)) {
                        const fileArray: File[] = files as File[]
                        fileArray.forEach((file) => {
                            if (file instanceof Blob)
                                formData.append(`files.${key.toString()}`, file, file.name)
                        })
                    } else {
                        if (files instanceof Blob)
                            formData.append(
                                `files.${key.toString()}`,
                                files as File,
                                (files as File).name
                            )
                    }
                }
            } else {
                data[key] = value
            }
        })
        formData.append("data", JSON.stringify(data))

        return formData
    }
}