import {
  IStrapiEntity,
  IStrapiRelationship,
  StrapiBaseFields,
} from "services/strapi"
import { IHome } from "features/homes"

export interface ITask extends StrapiBaseFields {
  type?:
    | "hold"
    | "signing"
    | "upload"
    | "scheduling"
    | "concierge"
    | "interactive"
  stepId?: string
  title?: string
  text?: any
  completedOn?: any
  home?: IStrapiRelationship<IStrapiEntity<IHome>>
  sequence?: number
  embeddedDocument: IStrapiRelationship<IStrapiEntity<IEmbeddedDocument>>
  formId: ETaskFormTypes
}

export interface IEmbeddedDocument {
  id: number
  externalId: string
  link: string
  roleId: string
  task?: IStrapiRelationship<IStrapiEntity<ITask>>
}

export interface ITaskFormValues extends Partial<Omit<ITask, "home">> {
  id?: number
}

export enum ETaskType {
  HOLD = "hold",
  SIGNING = "signing",
  UPLOAD = "upload",
  SCHEDULING = "scheduling",
  INTERACTIVE = "interactive",
  CONCIERGE = "concierge",
}

export enum ETaskFormTypes {
  CASH_OFFER = "cash-offer",
  SIMPLE_SALE = "simple-sale",
  REVIEW_PRICING = "review-pricing",
  REVIEW_LISTING = "review-listing",
  SCHEDULE_SIGN_AND_LOCKBOX_DELIVERY = "schedule-sign-lockbox-delivery",
  VERIFY_IDENTITY = "verify-identity",
  HOME_LISTING_PAYMENT = "home-listing-payment",
  REVIEW_OFFERS = "review-offers",
}
