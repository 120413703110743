import {
  Box,
  Button,
  Select,
  Stack,
  Title,
  Text,
  Paper,
  Grid,
  Slider,
  Card,
  Group,
  Center,
} from "@mantine/core"
import { isNotEmpty, useForm } from "@mantine/form"
import { IconArrowLeft, IconArrowRight } from "@tabler/icons"
import { Content, LoaderDefault } from "components/common"
import { IHomeFormReviewPricingFormValues, homeHooks } from "features/homes"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useUserRoutes } from "routes"
import { ErrorUtils } from "utils/error"
import { NumberUtils } from "utils"
import { useEffect } from "react"
import { Calendar } from "@mantine/dates"

export function HomeFormReviewPricingPage() {
  const { homeId } = useParams()
  const [searchParams] = useSearchParams()
  const taskId = searchParams.get("taskId")

  const { data: hpm, isLoading: isLoadingHpm } = homeHooks.useGetPricingModel(
    parseInt(homeId!)
  )

  const navigate = useNavigate()
  const { routesResolver } = useUserRoutes()

  const { mutateAsync } = homeHooks.useSubmitReviewPricing()

  const minScheduledDate = () => {
    const today = new Date()
    const tenDaysFromNow = new Date(today)
    tenDaysFromNow.setDate(today.getDate() + 10)
    return tenDaysFromNow
  }

  const maxScheduledDate = () => {
    const today = new Date()
    const sixMonthsFromNow = new Date(today)
    sixMonthsFromNow.setDate(today.getDate() + 180)
    return sixMonthsFromNow
  }

  const form = useForm<IHomeFormReviewPricingFormValues>({
    validate: {
      userListPrice: isNotEmpty("This field is required"),
    },
    validateInputOnChange: true,
    initialValues: {
      homeId: parseInt(homeId || ""),
      homePricingModelId: hpm?.id || -1,
      userListPrice: hpm?.recommendation || 0,
      taskId: parseInt(taskId || ""),
      tentativeActiveListingDate: minScheduledDate(),
    },
  })

  useEffect(() => {
    if (!form.values.userListPrice && hpm && !isLoadingHpm) {
      form.setFieldValue("userListPrice", hpm.recommendation)
      form.setFieldValue("homePricingModelId", hpm.id)
    }
  }, [hpm, isLoadingHpm])

  async function onSubmit() {
    if (!hpm?.id) return
    if (!form.validate().hasErrors) {
      const payload: IHomeFormReviewPricingFormValues = {
        homeId: parseInt(homeId || ""),
        homePricingModelId: hpm.id,
        userListPrice: form.values.userListPrice,
        taskId: form.values.taskId,
        tentativeActiveListingDate: form.values.tentativeActiveListingDate,
      }

      try {
        await mutateAsync(payload)
        navigate(routesResolver.getHomeDetailsById(parseInt(homeId || "")))
      } catch (error) {
        ErrorUtils.handleError(
          "Error submitting Review Pricing form",
          "We apologize, something went wrong submitting your form",
          error
        )
      }
    }
  }

  function getFloorCeilingPrice() {
    if (hpm?.recommendation) {
      const deviation = hpm.recommendation * 0.25
      return {
        floor: hpm.recommendation - deviation,
        ceiling: hpm.recommendation + deviation,
      }
    } else {
      return {
        floor: 0,
        ceiling: 0,
      }
    }
  }

  function getPercentageListPrice() {
    if (hpm?.recommendation) {
      const diff = form.values.userListPrice - hpm.recommendation
      return Math.round((diff / hpm.recommendation) * 100) / 100
    } else {
      return 0
    }
  }

  function getDaysToSell() {
    const baseDaysToSell = hpm?.averageSellDays || 30
    const percent = getPercentageListPrice() * 100
    const r = percent % 3
    const change = ((percent - r) / 3) * 7 + baseDaysToSell
    if (change < 20) {
      return 20
    } else {
      return change
    }
  }

  function getDeviationPercentage() {
    const percentageListPrice = getPercentageListPrice() * 100
    if (percentageListPrice > 0) {
      return (
        <Text span color="green" size="md">
          (+{percentageListPrice.toFixed()}%)
        </Text>
      )
    } else if (percentageListPrice < 0) {
      return (
        <Text span color="red" size="md">
          ({percentageListPrice.toFixed()}%)
        </Text>
      )
    } else {
      return ""
    }
  }

  function onChangeListPrice(num: number) {
    form.setFieldValue("userListPrice", num)
  }

  if (isLoadingHpm) {
    return <LoaderDefault text="Loading your home..." />
  }

  function renderBody() {
    if (hpm) {
      return (
        <form>
          <Grid gutter={48}>
            <Grid.Col md={4}>
              <Card h="100%" withBorder>
                <Stack h="100%" justify="center" spacing="xs" align="center">
                  <Text size="xl" weight={600}>
                    Estimated Market Value
                  </Text>
                  <Text size={72} weight={700} color="blue">
                    {NumberUtils.toShortened(hpm.recommendation, 2)}
                  </Text>
                </Stack>
              </Card>
            </Grid.Col>
            <Grid.Col md={4}>
              <Stack spacing={3} h="100%" align="center" justify="center">
                <Text color="dimmed" size="sm">
                  Slide left or right to change your list price
                </Text>
                <Slider
                  w="100%"
                  onChange={onChangeListPrice}
                  value={form.values.userListPrice}
                  step={5000}
                  min={getFloorCeilingPrice().floor}
                  max={getFloorCeilingPrice().ceiling}
                  marks={[
                    {
                      value: getFloorCeilingPrice().floor,
                      label: NumberUtils.toShortened(
                        getFloorCeilingPrice().floor,
                        2
                      ),
                    },
                    {
                      value: hpm.recommendation,
                      label: NumberUtils.toShortened(hpm.recommendation, 2),
                    },
                    {
                      value: getFloorCeilingPrice().ceiling,
                      label: NumberUtils.toShortened(
                        getFloorCeilingPrice().ceiling,
                        2
                      ),
                    },
                  ]}
                />
              </Stack>
            </Grid.Col>
            <Grid.Col md={4}>
              <Stack spacing={12}>
                <Card withBorder>
                  <Stack spacing={0} align="center">
                    <Text size="lg" weight={600}>
                      List Price
                    </Text>
                    <Group position="center">
                      <Text size={24} weight={700} color="blue">
                        {NumberUtils.toShortened(form.values.userListPrice, 2)}{" "}
                        {getDeviationPercentage()}
                      </Text>
                    </Group>
                  </Stack>
                </Card>
                <Card withBorder>
                  <Stack spacing={0} align="center">
                    <Text size="lg" weight={600}>
                      Estimated Days to Sell
                    </Text>
                    <Text size={24} weight={700} color="blue">
                      {getDaysToSell()}
                    </Text>
                  </Stack>
                </Card>
              </Stack>
            </Grid.Col>
          </Grid>
          <Center mt={"lg"}>
            <Card withBorder>
              <Stack>
                <Center>
                  <Text size="lg" weight={600}>
                    Preferred Listing Date
                  </Text>
                </Center>
                <Center>
                  <Text color="dimmed" size="sm">
                    Minimum 10 day lead time for scheduling a listing
                  </Text>
                </Center>
                <Center>
                  <Calendar
                    minDate={minScheduledDate()}
                    maxDate={maxScheduledDate()}
                    {...form.getInputProps("tentativeActiveListingDate")}
                  />
                  {form.errors.date && (
                    <Text color="red" size="sm">
                      *Please select a preferred date
                    </Text>
                  )}
                </Center>
              </Stack>
            </Card>
          </Center>
          <Button rightIcon={<IconArrowRight />} mt={"lg"} onClick={onSubmit}>
            Submit
          </Button>
        </form>
      )
    } else {
      return (
        <Text>
          An agent is reviewing your home's recommended listing price. We will
          notify you when this process has finished.
        </Text>
      )
    }
  }

  return (
    <Content.Body>
      <Stack>
        <Box>
          <Button
            leftIcon={<IconArrowLeft />}
            onClick={() =>
              navigate(
                routesResolver.getHomeDetailsById(parseInt(homeId || ""))
              )
            }
          >
            Back
          </Button>
        </Box>
        <Title>Review Pricing</Title>
        <Text mb={"lg"}>
          Provide the price you're aiming to sell your home for. This can be
          based on your desired return, market trends, or any recent property
          evaluations. Buyers will use this information to compare your home
          with others in the area, so choose an amount that aligns with your
          goals. Your concierge will review your price and offer advice as well.
          If you have any question, please contact your concierge.
        </Text>
        {renderBody()}
      </Stack>
    </Content.Body>
  )
}
